import { useState } from 'react';

const RightSlider = ({ slideArray }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const handleNextImage = () => {
    if (currentIndex === slideArray?.length - 1) {
      setCurrentIndex(0);
    } else {
      setCurrentIndex((index) => {
        return index + 1;
      });
    }
  };

  const handlePreviousImage = () => {
    if (currentIndex === 0) {
      setCurrentIndex(slideArray?.length - 1);
    } else {
      setCurrentIndex((index) => {
        return index - 1;
      });
    }
  };

  return (
    <>
      <div className="relative group corner-images ml-12 lg:ml-4 mr-[18px] lg:mr-0 py-2">
        <div className="hidden lg:hidden 2xl:block md:hidden">
          <div className="shadow-services rs-shadow"></div>
        </div>
        <img
          className="px-3"
          src={slideArray[currentIndex].image}
          alt="Ptw Art"
        />
      </div>
      <div className="hidden lg:block">
        <img
          className="opacity-[35%] h-[25rem] lg:h-[18rem] md:h-[15rem] 2xl:h-[25rem] xl:h-[20rem]"
          src={slideArray[(currentIndex + 1) % slideArray?.length].image}
          alt="hair-realtime"
        />
      </div>
      <div className="flex flex-col justify-around absolute h-[60%] top-[50%]  ml-8 mr-8 lg:ml-0 lg:mr-8 -translate-y-1/2 z-10">
        <div
          onClick={handlePreviousImage}
          className="flex justify-center items-center w-12 h-12 lg:w-16 lg:h-16 bg-white rounded-[50%] hover:bg-primary cursor-pointer hover-color"
        >
          {/* <img
            src="/images/arrow_left_pl.png"
            alt="left arrow"
            className="p-4 lg:p-0"
          /> */}
          <svg
            className="h-6 w-6 fill-black"
            viewBox="0 0 16 11"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              className="cls-1 arrow-color"
              fillRule="evenodd"
              strokeWidth=" 0px"
              d="M15.8,5.7v-.5H1.9l3.8-3.8.3-.3-.6-.6-.3.3L.4,5.3l-.3.3.3.3,4.6,4.6.3.3.6-.6-.3-.3-3.8-3.8h13.1v3.3h.9v-3.7Z"
            ></path>
          </svg>
        </div>
        <div
          onClick={handleNextImage}
          className="flex justify-center items-center w-12 h-12 lg:w-16 lg:h-16 bg-white rounded-[50%] hover:bg-primary cursor-pointer hover-color"
        >
          {/* <img
            src="/images/arrow_right_pl.png"
            alt="right arrow"
            className="p-4 lg:p-0"
          /> */}
          <svg
            className="h-6 w-6 fill-black"
            viewBox="0 0 16 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              className="arrow-color"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M0.171875 5.27543L0.171875 5.77543L0.671875 5.77543L13.4612 5.77543L9.89449 9.34217L9.54094 9.69573L10.248 10.4028L10.6016 10.0493L15.0219 5.62895L15.3755 5.2754L15.0219 4.92184L10.6016 0.501512L10.248 0.147959L9.54094 0.855066L9.89449 1.20862L13.4613 4.77543L1.17188 4.77543L1.17188 2.1127L1.17188 1.6127L0.171875 1.6127L0.171875 2.1127L0.171875 5.27543Z"
            ></path>
          </svg>
        </div>
      </div>
    </>
  );
};

export default RightSlider;
