// Component For Tell Us Your Story

import '../css/componentscss/TellUsYourStory.css';
import Text from './Text';
import Image from './Image';
import { useSelector } from 'react-redux';
import { navigateTo, stringToUpperCase } from '../constants/GeneralConstants';
import { useNavigate } from 'react-router-dom';
import { clientUrls } from '../constants/UrlConstants';
import AnimateOnScroll from './AnimationScroll';

const TellUsYourStory = () => {
  const navigate = useNavigate();
  const footerData = useSelector((state) => state.language.footerData);
  let text = footerData?.contactUsText
    ? footerData?.contactUsText.split(' ')
    : 'Contact Us';
  return (
    <>
      <div className="tell-top-line lg:hidden block"></div>
      <section className="footer-top lg:my-20 my-12 md:my-16">
        <AnimateOnScroll animation="fade-up" duration={1000}>
          <Text
            className="text-white vs:text-[3rem] sm:text-6xl md:text-7xl font-extrabold text-shadow leading-[1]"
            title={stringToUpperCase(
              footerData?.tellUsYourStoryText
                ? footerData?.tellUsYourStoryText
                : 'Tell Us Your Story',
            )}
            titleTag="h2"
          />
        </AnimateOnScroll>
        <div className="flex justify-center p-5 mb-8 max-w-md mx-auto ">
          <div className="absolute bottom-[-3rem] md:-bottom-16 bg-red-7001 w-full lg:hidden">
            <Image
              src="/images/tell-mobile-shadow.png"
              className="w-full sm:h-[25rem] lg:h-auto"
            />
          </div>
          <div
            className="relative w-48 h-48 bg-orange rounded-full flex items-center justify-center cursor-pointer z-10 transform hover:scale-105 duration-500 ease-in-out"
            onClick={(e) => navigate(navigateTo(clientUrls.contact))}
          >
            <div className="relative text-white text-center rounded-[4rem] ">
              <p className="text-2xl font-semibold uppercase">
                {footerData?.contactUsText ? text[0] : 'Contact'}
              </p>
              <p className="text-2xl font-semibold uppercase  ">
                {footerData?.contactUsText ? text[1] : 'Us'}
              </p>
            </div>
          </div>
        </div>

        <span className="whiteshadow"></span>
      </section>
    </>
  );
};

export default TellUsYourStory;
