import { Helmet } from 'react-helmet-async';

function ArtSiteSEO({ title, description, image = 'https://d36rxr3q27tpg8.cloudfront.net/art/images/1518studios.jpg', url }) {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta property="og:title" content={title} />
      <meta property="og:url" content={`https://1518studios.com${url}`} />
      <meta property="og:description" content={description} />
      <meta property="og:type" content="website" />
      <meta property="og:image" content={image} />
      <meta property="og:image:type" content="image/jpeg" />
      <meta property="og:image:url" content={image} />
      <meta property="og:image:secure_url" content={image} />
      <meta property="og:image:width" content="1500" />
      <meta property="og:image:height" content="500" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:image" content={image} />
      <meta name="twitter:url" content={`https://1518studios.com${url}`} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:site" content="1518Studios" />
      <meta name="twitter:creator" content="1518Studios" />
      <meta name="twitter:title" content={title} />
    </Helmet>
  );
}

export default ArtSiteSEO;
