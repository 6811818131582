import { useEffect, useRef, useState } from 'react';
import TellUsYourStory from '../../components/TellUsYourStory';
import {
  getFeturedProjectData,
  getProjectListStaticData,
} from '../../apiservices/ApiService';
import { getLanguage, languageCodes } from '../../constants/GeneralConstants';
import '../../css/pagescss/ProjectsPage.css';
import ProjectsList from './ProjectsList/ProjectsList';
import { Link, useParams } from 'react-router-dom';
import Loader from '../../components/Loader';
import Footer from '../../components/Footer';
import ArtSiteSEO from '../../components/ArtSiteSEO';
import AnimateOnScroll from '../../components/AnimationScroll';
import PageNotFound from '../../components/PageNotFound';
import { clientUrls } from '../../constants/UrlConstants';
const ProjectsPage = () => {
  const { lang } = useParams();
  const [data, setData] = useState();
  const [fetauredProjectData, setFeaturedProjctData] = useState();
  const [slideIndex, setSlideIndex] = useState(0);
  const [loader, setLoader] = useState(true);
  const [showArrows, setShowArrows] = useState(false);
  const [showArrowsMobile, setShowArrowsMobile] = useState(false);

  const projectScrollRef = useRef();
  const nextSlide = () => {
    if (slideIndex === fetauredProjectData?.length - 1) {
      setSlideIndex(0);
    } else {
      setSlideIndex((index) => {
        return index + 1;
      });
    }
  };

  const prevSlide = () => {
    if (slideIndex === 0) {
      setSlideIndex(fetauredProjectData?.length - 1);
    } else {
      setSlideIndex((index) => {
        return index - 1;
      });
    }
  };
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 500);
    const fetchData = async () => {
      const response = await getProjectListStaticData(getLanguage());
      setData(response);
      const featuredProjectsResponse =
        await getFeturedProjectData(getLanguage());
      setFeaturedProjctData(featuredProjectsResponse);
      setLoader(false);
    };
    fetchData();
  }, [lang]);
  if (lang) {
    if (!languageCodes.includes(lang)) {
      return <PageNotFound />;
    }
  }
  const handleTeamClick = () => {
    let ele = document.querySelector('.projectlist-container');
    if (ele != null) {
      const headerOffset = 100;

      const elementPosition = ele.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.scrollY - headerOffset;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      });
    }
  };
  if (lang && !languageCodes.includes(lang)) {
    return <PageNotFound />;
  }
  return (
    <>
      <ArtSiteSEO
        title={data?.projects?.seo?.title}
        description={data?.projects?.seo?.description}
        url={getLanguage() !== 'en' ? `/${getLanguage()}${clientUrls.projects}` : `${clientUrls.projects}`}
      />
      {loader ? (
        <>
          <Loader />
        </>
      ) : (
        <>
          {data?.projects && fetauredProjectData ? (
            <>
              <div className="relative">
                <section className="mt-20 lg:mt-36 3xl:mt-44">
                  <div className="project-heading relative project-pills-container flex justify-center">
                    {/* <h2 className="text-center font-extrabold lg:text-[60px] md:text-6xl 2xl:text-[98px] xl:text-[80px] text-4xl"> */}
                    <h2 className="font-extrabold text-[1.5rem] leading-[1.7rem] xs:text-4xl xs:leading-10 md:text-6xl md:leading-[4rem] lg:text-[56px] lg:leading-[3.5rem] xl:text-[84px] xl:leading-[5rem] 2xl:text-[98px] 2xl:leading-[5.8rem]">
                      <AnimateOnScroll animation="fade-up" duration={1000}>
                        <div className="flex gap-16">
                          <span className="ml-12 xs:ml-20  md:ml-32 lg:ml-0">
                            {' '}
                            {data?.projects?.work}
                          </span>

                          <div className="hidden lg:flex flex-col justify-end ">
                            <span className="block bg-white h-[.1rem] w-[2.1rem] opacity-[50%] "></span>
                            <p className="uppercase text-base xl:text-[18px] 2xl:text-xl 3xl:text-2xl">
                              {data?.projects?.subHeading1}
                            </p>
                            <p className="uppercase text-base xl:text-[18px] 2xl:text-xl 3xl:text-2xl">
                              {data?.projects?.subHeading2}
                            </p>
                          </div>
                        </div>
                      </AnimateOnScroll>
                      <AnimateOnScroll animation="fade-up" duration={1000}>
                        <span className="lg:-ml-12 xl:-ml-24">
                          {' '}
                          {data?.projects?.that} {data?.projects?.speaks}
                        </span>
                      </AnimateOnScroll>
                      <AnimateOnScroll animation="fade-up" duration={1000}>
                        <span className="ml-10 xs:ml-16 md:ml-28 lg:ml-24 xl:ml-36 2xl:ml-44">
                          {' '}
                          {data?.projects?.for} {data?.projects?.itself}
                        </span>
                      </AnimateOnScroll>
                    </h2>
                  </div>

                  <div className="w-[65%] block lg:hidden  m-auto mt-6">
                    <span className="block bg-white h-[.1rem] w-3 lg:w-[2.1rem] opacity-[50%] mb-[0.3rem] mr-[0.5rem] text-[5rem]"></span>
                    <p className="uppercase vs:text-[10px] xs:text-xs md:text-[14px]">
                      {data?.projects?.subHeading1}   
                    </p>
                    <p className="uppercase vs:text-[10px] xs:text-xs md:text-[14px]">
                      {data?.projects?.subHeading2}
                    </p>
                  </div>
                </section>
                <section className="mt-16 lg:mt-24 2xl:mt-28 relative overflow-hidden">
                  <div className="absolute ml-auto top-[8%] hidden lg:block">
                    <img src="/images/project-line-top.png" className="" />
                  </div>
                  <div className="absolute ml-auto bottom-[8%] hidden lg:block">
                    <img src="/images/project-line-bottom.png" className="" />
                  </div>
                  <div className="flex lg:flex-row flex-col-reverse w-full gap-8 2xl:gap-0 ">
                    <div className="w-4/5 lg:w-1/2 flex items-center justify-end mt-8 lg:mt-0">
                      <div className="w-4/5 lg:w-[75%] 2xl:w-[60%] flex flex-col justify-around h-full gap-8 lg:gap-10 xl:gap-8 2xl:gap-16">
                        <div className="mt-8 lg:mt-16 lg:h-1/4 ">
                          <p className="text-xs leading-4 lg:text-[14px] xl:text-base font-medium lg:leading-5 text-[#FEFBF7] uppercase">
                            {data?.projects?.selectedProjects}
                          </p>
                          <h3
                            key={slideIndex}
                            className="text-[22px] leading-[24px] lg:text-4xl xl:text-[53px]  font-extrabold lg:leading-[42px] xl:leading-[53px] mt-2 lg:mt-0 xl:mt-2 uppercase zoom-animate"
                          >
                            {fetauredProjectData[slideIndex]?.title}
                          </h3>
                        </div>
                        <div
                          className="lg:self-end cursor-pointer vs:mr-12 xs:mr-16 lg:h-1/4 lg:mb-4"
                          onClick={handleTeamClick}
                        >
                          <span className="text-primary text-xs lg:text-base font-medium uppercase">
                            {data?.projects?.viewAllProjects}
                          </span>
                          <img
                            className="inline w-4 h-6 ml-3 mt-4 "
                            src="/images/arrow-team.png"
                            alt="arrow img"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="flex w-4/5 lg:w-1/2  lg:justify-end relative ml-auto">
                      <div className="w-full flex justify-end items-center">
                        <div
                          className={`relative group ${showArrows ? 'corner-images-project' : ''} lg:after:left-16 xl:after:left-20 2xl:after:left-28 lg:before:-right-16 xl:before:-right-20 2xl:before:-right-28 md:w-3/4 lg:w-[56%]`}
                        >
                          <img
                            key={slideIndex}
                            className="h-auto w-full object-cover lg:p-2 zoom-animate lg:ml-16 xl:ml-20 2xl:ml-28 "
                            src={fetauredProjectData[slideIndex]?.featuredImage}
                            alt={fetauredProjectData[slideIndex]?.title}
                            onLoad={() => setShowArrowsMobile(true)}
                          />
                          {showArrowsMobile && (
                            <Link
                              to={
                                getLanguage() === 'en'
                                  ? `/projects/${fetauredProjectData[slideIndex]?.projectUrl}`
                                  : `/${lang}/projects/${fetauredProjectData[slideIndex]?.projectUrl}`
                              }
                            >
                              <button className="w-32 h-8 lg:w-24 lg:h-24 bg-primary rounded-full lg:rounded-[100%] lg:hidden  absolute bottom-[10%] lg:bottom-1/2 left-[50%] lg:left-2/4 translate-y-1/2 -translate-x-1/2  lg:group-hover:block transition duration-300 ease-in-out z-10">
                                {data?.projects?.view} {data?.projects?.project}
                              </button>
                            </Link>
                          )}

                          {showArrowsMobile && (
                            <div className="flex lg:hidden flex-col justify-around absolute h-[60%] w-[4rem] top-[50%] -left-6 -translate-y-1/2 z-10">
                              <div
                                className="flex justify-center items-center w-12 h-12  bg-white rounded-[50%] cursor-pointer "
                                onClick={prevSlide}
                              >
                                <svg
                                  className="h-6 w-6 fill-black "
                                  viewBox="0 0 16 11"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    className="arrow-color cls-1"
                                    fillRule="evenodd"
                                    strokeWidth=" 0px"
                                    // className="cls-1"
                                    d="M15.8,5.7v-.5H1.9l3.8-3.8.3-.3-.6-.6-.3.3L.4,5.3l-.3.3.3.3,4.6,4.6.3.3.6-.6-.3-.3-3.8-3.8h13.1v3.3h.9v-3.7Z"
                                  ></path>
                                </svg>
                              </div>
                              <div
                                className="flex  justify-center items-center w-12 h-12 lg:w-16 lg:h-16  bg-white rounded-[50%] cursor-pointer"
                                onClick={nextSlide}
                              >
                                <svg
                                  className="h-6 w-6 fill-black"
                                  viewBox="0 0 16 11"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    className="arrow-color"
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M0.171875 5.27543L0.171875 5.77543L0.671875 5.77543L13.4612 5.77543L9.89449 9.34217L9.54094 9.69573L10.248 10.4028L10.6016 10.0493L15.0219 5.62895L15.3755 5.2754L15.0219 4.92184L10.6016 0.501512L10.248 0.147959L9.54094 0.855066L9.89449 1.20862L13.4613 4.77543L1.17188 4.77543L1.17188 2.1127L1.17188 1.6127L0.171875 1.6127L0.171875 2.1127L0.171875 5.27543Z"
                                  ></path>
                                </svg>
                              </div>
                            </div>
                          )}
                        </div>
                        <div className="hidden lg:block  w-[44%] relative">
                          <img
                            className="w-full h-auto opacity-[40%] object-cover  lg:ml-20 xl:ml-24 2xl:ml-32"
                            src={
                              fetauredProjectData[
                                (slideIndex + 1) % fetauredProjectData?.length
                              ]?.featuredImage
                            }
                            alt={
                              fetauredProjectData[
                                (slideIndex + 1) % fetauredProjectData?.length
                              ]?.title
                            }
                            onLoad={() => {
                              setShowArrows(true);
                            }}
                          />
                          <div
                            className={` hidden ${showArrows ? 'lg:flex' : ''} gap-8 flex-col justify-around absolute h-[60%] w-[4rem] top-[50%] right-3 2xl:right-6 3xl:right-12 -translate-y-1/2 z-10`}
                          >
                            <div
                              className="flex justify-center items-center w-12 h-12 lg:w-16 lg:h-16 2xl:w-20 2xl:h-20 3xl:w-24 3xl:h-24 bg-white rounded-[50%] group hover:bg-primary cursor-pointer "
                              onClick={prevSlide}
                            >
                              <svg
                                className="h-6 w-6 fill-black group-hover:fill-white"
                                viewBox="0 0 16 11"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  className="arrow-color cls-1"
                                  fillRule="evenodd"
                                  strokeWidth=" 0px"
                                  // className="cls-1"
                                  d="M15.8,5.7v-.5H1.9l3.8-3.8.3-.3-.6-.6-.3.3L.4,5.3l-.3.3.3.3,4.6,4.6.3.3.6-.6-.3-.3-3.8-3.8h13.1v3.3h.9v-3.7Z"
                                ></path>
                              </svg>
                            </div>
                            <div
                              className="flex  justify-center items-center w-12 h-12 lg:w-16 lg:h-16 2xl:w-20 2xl:h-20 3xl:w-24 3xl:h-24 bg-white rounded-[50%] group hover:bg-primary cursor-pointer"
                              onClick={nextSlide}
                            >
                              <svg
                                className="h-6 w-6 fill-black group-hover:fill-white"
                                viewBox="0 0 16 11"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  className="arrow-color"
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M0.171875 5.27543L0.171875 5.77543L0.671875 5.77543L13.4612 5.77543L9.89449 9.34217L9.54094 9.69573L10.248 10.4028L10.6016 10.0493L15.0219 5.62895L15.3755 5.2754L15.0219 4.92184L10.6016 0.501512L10.248 0.147959L9.54094 0.855066L9.89449 1.20862L13.4613 4.77543L1.17188 4.77543L1.17188 2.1127L1.17188 1.6127L0.171875 1.6127L0.171875 2.1127L0.171875 5.27543Z"
                                ></path>
                              </svg>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <div className="custom-border-project-list mt-9  lg:hidden "></div>

                <div ref={projectScrollRef} className="projectlist-container">
                  {' '}
                  <ProjectsList data={data} handleTeamClick={handleTeamClick} />
                </div>
                <div className="right-border-project-listing hidden lg:block"></div>
                <div className="left-border-project-listing hidden lg:block"></div>
              </div>
              <div className="mt-20">
                <TellUsYourStory />
                <Footer />
              </div>
            </>
          ) : (
            ''
          )}
        </>
      )}
    </>
  );
};

export default ProjectsPage;
