// Component For Images

import '../css/componentscss/Image.css';

const Image = ({ src, alt, size = 'md', className }) => {
  // let sizeClass = 'w-full h-full';
  // switch (size) {
  //   case 'sm':
  //     sizeClass = 'w-24 h-24';
  //     break;
  //   case 'lg':
  //     sizeClass = 'w-64 h-64';
  //     break;
  //   case 'xl':
  //     sizeClass = 'w-96 h-96';
  //     break;
  //   case 'full':
  //     sizeClass = 'w-full h-full';
  //     break;
  //   case 'md':
  //   default:
  //     sizeClass = 'w-48 h-48';
  //     break;
  // }

  return (
    <img
      src={src}
      alt={alt}
      className={`object-cover ${className}`} //${sizeClass}
    />
  );
};

export default Image;
