import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  selectedLanguage: 'en',
  footerData: [],
  loader: true,
};

const languageSlice = createSlice({
  name: 'language',
  initialState,
  reducers: {
    setLanguage: (state, action) => {
      state.selectedLanguage = action.payload;
    },
    setFooterData: (state, action) => {
      state.footerData = action.payload;
    },
    setLoader: (state, action) => {
      state.loader = action.payload;
    },
  },
});

export const { setLanguage, setFooterData, setLoader } = languageSlice.actions;
export default languageSlice.reducer;
