import { useState } from 'react';
import Image from './Image';

const ServicesSliderArt = ({ slideArray }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const secondIndex = (currentIndex + 1) % slideArray.length;

  const handleNextImage = () => {
    setCurrentIndex((index) => (index + 1) % slideArray.length);
  };

  const handlePreviousImage = () => {
    setCurrentIndex(
      (index) => (index - 1 + slideArray.length) % slideArray.length,
    );
  };

  return (
    <div className="my-12 lg:my-20 w-[90%] mx-auto relative flex justify-end">
      <Image
        className="mr-2"
        src={slideArray[currentIndex].image}
        alt="Ptw art"
      />
      <Image
        className="ml-2"
        src={slideArray[secondIndex].image}
        alt="Ptw art"
      />
      <div className="flex flex-col justify-around absolute h-[95%] top-[50%] -translate-y-1/2 -translate-x-[14rem] z-10">
        <div
          onClick={handlePreviousImage}
          className="flex justify-center items-center w-12 h-12 lg:w-24 lg:h-24 bg-white rounded-[50%]"
        >
          <img
            src="/images/arrow_left_pl.png"
            alt="left arrow"
            className="p-4 lg:p-0"
          />
        </div>
        <div
          onClick={handleNextImage}
          className="flex justify-center items-center w-12 h-12 lg:w-24 lg:h-24 bg-white rounded-[50%]"
        >
          <img
            src="/images/arrow_right_pl.png"
            alt="right arrow"
            className="p-4 lg:p-0"
          />
        </div>
      </div>
    </div>
  );
};

export default ServicesSliderArt;
