// this Component is for Project list
import { Link, useParams, useLocation } from 'react-router-dom';
import '../../css/pagescss/utilities.css';
import '../../css/pagescss/ProjectInner.css';
import { getProjectDetailData } from '../../apiservices/ApiService';
import { useEffect, useRef, useState } from 'react';
import parse from 'html-react-parser';
import {
  getLanguage,
  languageCodes,
  navigateTo,
  setBrowserTitle,
} from '../../constants/GeneralConstants';
import TellUsYourStory from '../../components/TellUsYourStory';
import { clientUrls } from '../../constants/UrlConstants';
import Loader from '../../components/Loader';
import PageNotFound from '../../components/PageNotFound';
import Footer from '../../components/Footer';
import AnimateOnScroll from '../../components/AnimationScroll';
import ArtSiteSEO from '../../components/ArtSiteSEO';
const ProjectInner = () => {
  const { pathname } = useLocation();
  const { lang, id } = useParams();
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [image, setImage] = useState('');
  const [isImageLoading, setIsImageLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 300);
  }, [pathname]);
  useEffect(() => {
    setIsLoading(true);
  }, [id]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await getProjectDetailData(getLanguage(), id);

      setData(response);
      setImage(
        response?.bannerImageLowQuality
          ? response?.bannerImageLowQuality
          : response?.bannerImage,
      );
      setBrowserTitle(response?.title);
    };
    fetchData();
  }, [lang, id]);

  if (lang) {
    if (!languageCodes.includes(lang)) {
      return <PageNotFound />;
    }
  }

  if (data?.status === 404) {
    return <PageNotFound />;
  }
  const updateImageStatus = (bannerImage) => {
    setImage(bannerImage);
    setIsImageLoading(false);
    setIsLoading(false);
  };
  const getIframe = (title, url) => {
    if (url.includes('youtube')) {
      const id = url.split('=')[1];
      return `<iframe title=\"${title}\" width=\"640\" height=\"360\" src=\"https://www.youtube-nocookie.com/embed/${id}?rel=0&amp;showinfo=0\" frameborder=\"0\" allow=\"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share\" referrerpolicy=\"strict-origin-when-cross-origin\" allowfullscreen></iframe>`;
    } else if (url.includes('vimeo')) {
      const id = url.split('vimeo.com/')[1];
      return `<iframe title=\"${title}\" width=\"640\" height=\"360\" src=\"https://player.vimeo.com/video/${id}\" frameborder=\"0\" allow=\"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share\" referrerpolicy=\"strict-origin-when-cross-origin\" allowfullscreen></iframe>`;
    } else if (url.includes('dailymotion')) {
      const id = url.split('dailymotion.com/video/')[1];
      return `<iframe title=\"${title}\" width=\"640\" height=\"360\" src=\"https://www.dailymotion.com/embed/video/${id}\" frameborder=\"0\" allow=\"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share\" referrerpolicy=\"strict-origin-when-cross-origin\" allowfullscreen></iframe>`;
    }
  };
  return (
    <>
    <ArtSiteSEO
    title={'Immersive Game Development & Art Services | 1518 Studios'}
    description={'At 1518 Studios, we bring your game to life with expert art and development services. Explore our immersive projects and innovative team today.'}
    url={getLanguage() !== 'en' ? `/${getLanguage()}${clientUrls.projects}/${id}` : `${clientUrls.projects}/${id}`}
    image={data?.bannerImage}
  />
      {isLoading && <Loader />}
      <>
        <div className={isLoading ? 'hidden' : 'block'}>
          <div className="relative mt-24">
            {data && (
              <div className="project-inner">
                {/* project inner page section image starts here  */}
                <div className="max-w-full overflow-hidden px-4 lg:px-6 relative flex items-center justify-center">
                  <div className=" lg:px-4">
                    <img
                      src={image}
                      alt="banner-image"
                      onLoad={(e) => updateImageStatus(data?.bannerImage)}
                      className="w-full block h-auto max-w-full relative zoom-animate"
                      style={{
                        filter: isImageLoading ? 'blur(20px)' : 'none',
                        transition: 'filter 0.3s ease-out',
                      }}
                    />
                  </div>
                </div>
                {/* project inner page section image starts here  */}

                {/* released and porject name, description right side section starts here  */}
                <div className="lg:px-24 xl:px-36 mt-12 mb-12 lg:mb-24 sm:px-2">
                  <div className="px-4 flex lg:gap-32 flex-col lg:flex-row">
                    <p className="lg:w-1/3 text-xs leading-8 mb-4 lg:mb-0 lg:text-[28px] font-medium uppercase mt-1">
                      {data?.project_static_content?.released}{' '}
                      {data?.releaseYears}
                    </p>
                    <div className="lg:w-2/3">
                      <AnimateOnScroll animation="fade-up" duration={1000}>
                        <h1 className="text-[22px] leading-[22px] lg:text-[58px] font-extrabold uppercase lg:leading-[60px] mb-4">
                          {data?.title}
                        </h1>
                      </AnimateOnScroll>
                      <div className="leading-[16px] lg:leading-[30px] detail-page-description">
                        {data?.projectDescription
                          ? parse(data?.projectDescription)
                          : ''}
                      </div>

                      <div className="flex mt-12 flex-col lg:flex-row gap-6 lg:gap-0">
                        <div className="w-1/2 ">
                          <p className="text-primary text-xs lg:text-base font-semibold uppercase">
                            {data?.project_static_content?.client}
                          </p>
                          <p className="mt-2 leading-7 text-sm lg:text-base">
                            {data?.clientName}
                          </p>
                        </div>
                        <div className="w-1/2 ">
                          <p className="text-primary text-xs lg:text-base font-semibold uppercase">
                            {data?.project_static_content?.weProvided}
                          </p>
                          <ul className="leading-7 list-disc mt-2 pl-8 marker:text-primary text-sm lg:text-base">
                            {data?.services?.map((service) => (
                              <li key={service}>{service}</li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* released and porject name, description right side section ends here  */}

                  <div className="custom-top-border mt-9 mb-8 hidden "></div>

                  {/* youtube video section starts here  */}
                  {data?.videoURL && (
                    <AnimateOnScroll animation="zoom-in">
                      <div className="px-4 mt-5 lg:mt-20 video-section relative pt-[67%] md:pt-[53%] vs:pt-[50%] pad-55 mx-4">
                        {/* <img src="/images/project_inner_media.png" /> */}
                        {parse(getIframe(data?.title, data?.videoURL))}
                      </div>
                    </AnimateOnScroll>
                  )}
                  {/* youtube video section ends here  */}

                  {/* desktop prev and next buttons code starts here */}
                  <section className="px-4 mt-20 uppercase hidden lg:block">
                    <div className="flex justify-between ">
                      <p className="flex items-center gap-2 font-medium w-1/2 h-9 cursor-pointer hover-prev">
                        {data?.prevProject?.title && (
                          <>
                            <span> {data?.project_static_content?.prev}</span>
                            <span>
                              {' '}
                              <svg
                                width="11"
                                height="11"
                                viewBox="0 0 11 11"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <circle
                                  cx="5.80603"
                                  cy="5.19666"
                                  r="5.20349"
                                  fill="#FF2E29"
                                />
                              </svg>
                            </span>
                          </>
                        )}
                      </p>
                      <p className="flex items-center justify-end gap-2 font-medium w-1/2 h-9 cursor-pointer hover-next">
                        {data?.nextProject?.title && (
                          <>
                            <span>
                              <svg
                                width="11"
                                height="11"
                                viewBox="0 0 11 11"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <circle
                                  cx="5.80603"
                                  cy="5.19666"
                                  r="5.20349"
                                  fill="#FF2E29"
                                />
                              </svg>
                            </span>
                            <span> {data?.project_static_content?.next}</span>
                          </>
                        )}
                      </p>
                    </div>
                    <div className="flex justify-between font-size-26 font-extrabold ">
                      <div className="relative group max-w-[50%]">
                        {data?.prevProject?.title && (
                          <>
                            {' '}
                            <Link
                              to={navigateTo(
                                `${clientUrls.projects}/${data?.prevProject?.url}`,
                              )}
                            >
                              <h2 className="font-height-30 text-left inline-block">
                                {data?.prevProject?.title}
                              </h2>
                              <button className="bg-primary bg-no-repeat bg-center border-none rounded-full cursor-pointer hidden  group-hover:block h-[6.3rem]  absolute left-[50%] -top-1/2 w-[6.3rem] background-arrow-left transform translate-x-[-50%] prev-button"></button>
                            </Link>
                          </>
                        )}
                      </div>
                      <div className="relative group max-w-[50%]">
                        {data?.nextProject?.title && (
                          <>
                            <Link
                              to={navigateTo(
                                `${clientUrls.projects}/${data?.nextProject?.url}`,
                              )}
                            >
                              <h2 className="font-height-30 text-right inline-block">
                                {data?.nextProject?.title}
                              </h2>
                              <button className="bg-primary bg-no-repeat bg-center border-none rounded-full cursor-pointer hidden group-hover:block h-[6.3rem]  absolute left-[50%] -top-1/2 w-[6.3rem] background-arrow-right transform translate-x-[-50%] next-button"></button>
                            </Link>
                          </>
                        )}
                      </div>
                    </div>
                  </section>
                  {/*desktop  prev and next buttons code ends here */}

                  {/* mobile prev and next buttons code starts here  */}
                  <section className="px-4 mt-10 flex gap-8 justify-end lg:hidden">
                    {data?.prevProject?.title && (
                      <Link
                        className={
                          'p-3 rounded-full border z-10 prevarrow hover:bg-red-700'
                        }
                        to={navigateTo(
                          `${clientUrls.projects}/${data?.prevProject?.url}`,
                        )}
                        type="button"
                      >
                        <svg
                          className="h-5 w-5"
                          viewBox="0 0 16 11"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            strokeWidth=" 0px"
                            fill="#fefbf7"
                            className="cls-1"
                            d="M15.8,5.7v-.5H1.9l3.8-3.8.3-.3-.6-.6-.3.3L.4,5.3l-.3.3.3.3,4.6,4.6.3.3.6-.6-.3-.3-3.8-3.8h13.1v3.3h.9v-3.7Z"
                          />
                        </svg>
                      </Link>
                    )}
                    {data?.nextProject?.title && (
                      <Link
                        className={
                          'p-3 rounded-full border z-10 nextarrow hover:bg-red-700'
                        }
                        to={navigateTo(
                          `${clientUrls.projects}/${data?.nextProject?.url}`,
                        )}
                        type="button"
                      >
                        <svg
                          className="h-5 w-5"
                          viewBox="0 0 16 11"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M0.171875 5.27543L0.171875 5.77543L0.671875 5.77543L13.4612 5.77543L9.89449 9.34217L9.54094 9.69573L10.248 10.4028L10.6016 10.0493L15.0219 5.62895L15.3755 5.2754L15.0219 4.92184L10.6016 0.501512L10.248 0.147959L9.54094 0.855066L9.89449 1.20862L13.4613 4.77543L1.17188 4.77543L1.17188 2.1127L1.17188 1.6127L0.171875 1.6127L0.171875 2.1127L0.171875 5.27543Z"
                            fill="white"
                          ></path>
                        </svg>
                      </Link>
                    )}
                  </section>
                  {/* mobile prev and next code buttons ends here  */}
                </div>
              </div>
            )}

            <div className="custom-right-border hidden lg:block lg:right-20 xl:right-32 2xl:right-28"></div>
            <div className="custom-top-border hidden md:block mt-9 mb-8 lg:hidden "></div>

            <TellUsYourStory />
          </div>
          <Footer />
        </div>
      </>
    </>
  );
};

export default ProjectInner;
