import '../css/componentscss/Pagination.css';

const Pagination = ({ currentPage, totalPages, onPaginationValueChange }) => {
  const handlePageClick = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      onPaginationValueChange(pageNumber);
    }
  };

  const renderPageNumbers = () => {
    const pageNumbers = [...Array(totalPages).keys()].map((n) => n + 1);

    const visiblePages = 6;
    const halfVisible = Math.floor(visiblePages / 2);

    if (totalPages <= visiblePages) {
      return pageNumbers.map((pageNumber) => (
        <a
          key={pageNumber}
          className={
            pageNumber === currentPage
              ? 'pages-link mx-[0.5rem] xs:mx-[0.7rem] active'
              : 'pages-link mx-[0.5rem] xs:mx-[0.7rem]'
          }
          href="#"
          onClick={(e) => {
            e.preventDefault();
            handlePageClick(pageNumber);
          }}
        >
          {pageNumber}
        </a>
      ));
    }

    if (currentPage <= halfVisible + 1) {
      return [
        ...pageNumbers.slice(0, visiblePages - 1).map((pageNumber) => (
          <a
            key={pageNumber}
            className={
              pageNumber === currentPage
                ? 'pages-link mx-[0.5rem] xs:mx-[0.7rem] active'
                : 'pages-link mx-[0.5rem] xs:mx-[0.7rem]'
            }
            href="#"
            onClick={(e) => {
              e.preventDefault();
              handlePageClick(pageNumber);
            }}
          >
            {pageNumber}
          </a>
        )),
        <span key="ellipsis-end">...</span>,
        <a
          key={totalPages}
          className={
            totalPages === currentPage
              ? 'pages-link mx-[0.5rem] xs:mx-[0.7rem] active'
              : 'pages-link mx-[0.5rem] xs:mx-[0.7rem]'
          }
          href="#"
          onClick={(e) => {
            e.preventDefault();
            handlePageClick(totalPages);
          }}
        >
          {totalPages}
        </a>,
      ];
    }

    if (currentPage >= totalPages - halfVisible) {
      return [
        <a
          key={1}
          className={
            1 === currentPage
              ? 'pages-link mx-[0.5rem] xs:mx-[0.7rem] active'
              : 'pages-link mx-[0.5rem] xs:mx-[0.7rem]'
          }
          href="#"
          onClick={(e) => {
            e.preventDefault();
            handlePageClick(1);
          }}
        >
          1
        </a>,
        <span key="ellipsis-start">...</span>,
        ...pageNumbers
          .slice(totalPages - (visiblePages - 1))
          .map((pageNumber) => (
            <a
              key={pageNumber}
              className={
                pageNumber === currentPage
                  ? 'pages-link mx-[0.5rem] xs:mx-[0.7rem] active'
                  : 'pages-link mx-[0.5rem] xs:mx-[0.7rem]'
              }
              href="#"
              onClick={(e) => {
                e.preventDefault();
                handlePageClick(pageNumber);
              }}
            >
              {pageNumber}
            </a>
          )),
      ];
    }

    return [
      <a
        key={1}
        className={
          1 === currentPage
            ? 'pages-link mx-[0.5rem] xs:mx-[0.7rem] active'
            : 'pages-link mx-[0.5rem] xs:mx-[0.7rem]'
        }
        href="#"
        onClick={(e) => {
          e.preventDefault();
          handlePageClick(1);
        }}
      >
        1
      </a>,
      <span key="ellipsis-start">...</span>,
      ...pageNumbers
        .slice(currentPage - halfVisible, currentPage + halfVisible - 1)
        .map((pageNumber) => (
          <a
            key={pageNumber}
            className={
              pageNumber === currentPage
                ? 'pages-link mx-[0.5rem] xs:mx-[0.7rem] active'
                : 'pages-link mx-[0.5rem] xs:mx-[0.7rem]'
            }
            href="#"
            onClick={(e) => {
              e.preventDefault();
              handlePageClick(pageNumber);
            }}
          >
            {pageNumber}
          </a>
        )),
      <span key="ellipsis-end">...</span>,
      <a
        key={totalPages}
        className={
          totalPages === currentPage
            ? 'pages-link mx-[0.5rem] xs:mx-[0.7rem] active'
            : 'pages-link mx-[0.5rem] xs:mx-[0.7rem]'
        }
        href="#"
        onClick={(e) => {
          e.preventDefault();
          handlePageClick(totalPages);
        }}
      >
        {totalPages}
      </a>,
    ];
  };

  return (
    <>
      <button
        className="pagination-left-button mr-4 xs:mr-6 lg:mr-12"
        onClick={() => handlePageClick(currentPage - 1)}
        disabled={currentPage === 1}
      >
        <svg
          width="17"
          height="11"
          viewBox="0 0 17 11"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16.1348 5.4999L16.1348 4.9999L15.6348 4.9999L2.84546 4.9999L6.41215 1.43322L6.7657 1.07966L6.05859 0.372558L5.70504 0.726112L1.28471 5.14644L0.931156 5.49999L1.28471 5.85355L5.70504 10.2739L6.05859 10.6274L6.7657 9.92032L6.41215 9.56677L2.84528 5.9999L15.1348 5.9999L15.1348 8.66263L15.1348 9.16263L16.1348 9.16263L16.1348 8.66263L16.1348 5.4999Z"
            fill="#FEFBF7"
            stroke="#fefbf7"
            strokeWidth="0.8"
          ></path>
        </svg>
      </button>
      {renderPageNumbers()}
      <button
        className="pagination-right-button ml-4 xs:ml-6 lg:ml-12"
        onClick={() => handlePageClick(currentPage + 1)}
        disabled={currentPage === totalPages}
      >
        <svg
          width="16"
          height="12"
          viewBox="0 0 16 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0.138672 6.0001L0.138672 6.5001L0.638672 6.5001L13.428 6.5001L9.86129 10.0668L9.50774 10.4203L10.2148 11.1274L10.5684 10.7739L14.9887 6.35356L15.3423 6.00001L14.9887 5.64645L10.5684 1.22612L10.2148 0.872569L9.50774 1.57968L9.86129 1.93323L13.4282 5.5001L1.13867 5.5001L1.13867 2.83737L1.13867 2.33737L0.138672 2.33737L0.138672 2.83737L0.138672 6.0001Z"
            fill="#FEFBF7"
            stroke="#fefbf7"
            strokeWidth="0.8"
          ></path>
        </svg>
      </button>
    </>
  );
};

export default Pagination;
