import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';

const AnimateOnScroll = ({
  children,
  animation,
  duration = 1000,
  delay = 0,
}) => {
  useEffect(() => {
    AOS.init({
      duration,
      delay,
      once: true,
    });
    AOS.refresh();
  }, [duration, delay]);

  return <div data-aos={animation}>{children}</div>;
};

export default AnimateOnScroll;
