// Router Urls
export const clientUrls = {
  home: '/',
  homeWithLanguage: '/:lang?',
  services: '/services',
  servicesWithLanguage: '/:lang/services',
  projects: '/projects',
  projectsWithLanguage: '/:lang/projects',
  projectDescription: '/projects/:id',
  projectDescriptionWithLanguage: '/:lang/projects/:id',
  portfolio: '/portfolio',
  portfolioWithLanguage: '/:lang/portfolio',
  subPortfolio: '/portfolio/:id',
  subPortfolioWithLanguage: '/:lang/portfolio/:id',
  morePortfolio: '/portfolio/pdfportfolio/:slug',
  morePortfolioWithLanguage: '/:lang/portfolio/pdfportfolio/:slug',
  contact: '/contact',
  contactWithLanguage: '/:lang/contact',
  pageNotFoundWithLang: '/:lang/*',
};
