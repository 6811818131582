export const ButtonsUnderSwiper = () => {
  return (
    <div className="crousal-wrap">
      <span className="trial-pin-2"></span>
      <span className="crousal-pin pin-x"></span>
      <span className="crousal-pin pin-x0"></span>
      <span className="crousal-pin pin-x1"></span>
      <span className="crousal-pin pin-x2"></span>
      <span className="trial-pin-1"></span>
      <span className="crousal-center-button"></span>
      <span className="crousal-pin pin-y0"></span>
      <span className="crousal-pin pin-y "></span>
      <span className="crousal-pin pin-y1"></span>
      <span className="crousal-pin pin-y2"></span>
      <span className="trial-pin-3"></span>
    </div>
  );
};
