// animationUtils.js
import anime from 'animejs';

export const AnimateElements = (elements) => {
  elements.forEach(
    ({
      targets,
      translateX,
      opacity,
      scale,
      duration = 1500,
      easing = 'easeOutSine',
    }) => {
      anime({
        targets,
        translateX,
        opacity,
        scale,
        duration,
        easing,
      });
    },
  );
};
