const NetWorkError = () => {
  return (
    <div className="flex items-center justify-center h-screen bg-black-100">
      <div className="text-center">
        <h1 className="text-6xl font-bold text-red-600">Network Error</h1>
        <p className="mt-4 text-xl text-gray-700">
          Oops! Something went wrong. Please check your connection and try
          again.
        </p>
      </div>
    </div>
  );
};

export default NetWorkError;
