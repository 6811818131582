// Component For Swipers

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { navigateTo } from '../constants/GeneralConstants';
import { clientUrls } from '../constants/UrlConstants';
import { useNavigate } from 'react-router-dom';
import PILImg from './PILImg';

const HomeSwiper = ({ slides, sliderRef, setImageWidth }) => {
  const navigate = useNavigate();
  const navigatingToProjectInnerPage = (e, url, index) => {
    let { target } = e;
    if (
      target.offsetParent &&
      target.offsetParent.classList &&
      target.offsetParent.classList.contains('swiper-slide-active')
    ) {
      navigate(navigateTo(`${clientUrls.projects}/${url}`));
    } else {
    }
  };
  return (
    <Swiper
      slidesPerView={5}
      spaceBetween={30}
      loop={true}
      ref={sliderRef}
      centeredSlides={true}
      draggable={false}
      allowTouchMove={false}
      breakpoints={{
        320: {
          slidesPerView: 1,
          spaceBetween: 0,
        },
        640: {
          slidesPerView: 3,
          spaceBetween: 20,
        },
        1024: {
          slidesPerView: 5,
          spaceBetween: 10,
        },
      }}
      navigation={false}
      className="mySwiper mobile-swiper"
      onAfterInit={() => {
        const centerImage = document.querySelector(
          '.swiper-slide-active .home-swiper',
        );
        setImageWidth(centerImage?.clientWidth);
      }}
    >
      {slides.map((image, index) => (
        <SwiperSlide
          key={index}
          onClick={(e) =>
            navigatingToProjectInnerPage(e, image.projectUrl, index)
          }
        >
          <div className="home-swiper z-99">
            <PILImg
              size="sm"
              className="w-full"
              src={image.projectHomeImage}
              lowQaulitySrc={
                image?.projectHomeImageLowQuality
                  ? image?.projectHomeImageLowQuality
                  : image?.projectHomeImage
              }
              alt={image.projectUrl}
            />
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default HomeSwiper;
