import React, { useRef, useState, useEffect } from 'react';
import YouTube from 'react-youtube';
import '../css/pagescss/ServicesPage.css';

const YouTubePlayer = ({ videoId, onPlay, playingVideo }) => {
  const playerRef = useRef(null);
  const [buttonText, setButtonText] = useState('Play');
  const [isMuted, setIsMuted] = useState(false);

  const onPlayerReady = (event) => {
    playerRef.current = event.target;
  };

  const handlePlayPause = (e) => {
    e.stopPropagation();
    const player = playerRef.current;
    if (player.getPlayerState() === 1) {
      player?.pauseVideo();
      setButtonText('Play');
    } else {
      onPlay(videoId);
      player?.playVideo();
      setButtonText('Pause');
    }
  };

  useEffect(() => {
    if (playingVideo !== videoId && playerRef.current) {
      playerRef.current?.pauseVideo();
      setButtonText('Play');
    }
  }, [playingVideo, videoId]);

  const onStateChange = (event) => {
    if (event.data === 1) {
      // Video is playing
      setButtonText('Pause');
    } else if (event.data === 2) {
      // Video is paused
      setButtonText('Play');
    } else if (event.data === 0) {
      setButtonText('Play');
      playerRef.current?.stopVideo();
    }
  };

  const handleMuteUnmute = () => {
    const player = playerRef.current;
    if (isMuted) {
      player.unMute();
      setIsMuted(false);
    } else {
      player.mute();
      setIsMuted(true);
    }
  };
  return (
    <div className="flex justify-center items-center relative w-[90%] lg:w-[80%] mx-auto h-auto group">
      <div
        className=" w-full z-10 video-responsive relative"
        onClick={(e) => e.stopPropagation()} // Prevent iframe clicks
      >
        <div className="w-full h-full absolute  z-20 top-0 left-0"></div>

        <YouTube
          videoId={videoId}
          opts={{
            height: '100%',
            width: '100%',
            playerVars: {
              autoplay: 0,
              controls: 0,
              rel: 0,
              showinfo: 0,
              iv_load_policy: 3,
            },
          }}
          onReady={onPlayerReady}
          onStateChange={onStateChange}
        />
      </div>
      <div className="absolute vs:top-[70%] xs:top-[80%] lg:top-[90%] left-[4%] lg:left-[5%] float-left w-[1.5rem] z-30 ">
        {/* mute */}
        {isMuted ? (
          <div
            className="unmute-icon cursor-pointer z-50"
            onClick={handleMuteUnmute}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="size-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M17.25 9.75 19.5 12m0 0 2.25 2.25M19.5 12l2.25-2.25M19.5 12l-2.25 2.25m-10.5-6 4.72-4.72a.75.75 0 0 1 1.28.53v15.88a.75.75 0 0 1-1.28.53l-4.72-4.72H4.51c-.88 0-1.704-.507-1.938-1.354A9.009 9.009 0 0 1 2.25 12c0-.83.112-1.633.322-2.396C2.806 8.756 3.63 8.25 4.51 8.25H6.75Z"
              />
            </svg>
          </div>
        ) : (
          <div
            className="mute-icon cursor-pointer z-50"
            onClick={handleMuteUnmute}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="size-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M19.114 5.636a9 9 0 0 1 0 12.728M16.463 8.288a5.25 5.25 0 0 1 0 7.424M6.75 8.25l4.72-4.72a.75.75 0 0 1 1.28.53v15.88a.75.75 0 0 1-1.28.53l-4.72-4.72H4.51c-.88 0-1.704-.507-1.938-1.354A9.009 9.009 0 0 1 2.25 12c0-.83.112-1.633.322-2.396C2.806 8.756 3.63 8.25 4.51 8.25H6.75Z"
              />
            </svg>
          </div>
        )}
      </div>
      <div className="hidden justify-center p-5 mb-8 max-w-md mx-auto -translate-y-2/4 -translate-x-2/4 top-[50%] left-[50%] absolute z-50 group-hover:flex">
        <div
          className="relative vs:w-20 vs:h-20 xs:w-24 xs:h-24 lg:w-32 lg:h-32 bg-orange rounded-full flex items-center justify-center cursor-pointer "
          onClick={handlePlayPause}
        >
          <div className="relative text-white text-center z-10 rounded-[4rem] text-[12px] lg:text-[16px] font-medium uppercase">
            {buttonText}
          </div>
        </div>
      </div>
    </div>
  );
};

export default YouTubePlayer;
