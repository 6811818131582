// Component For Images

import { useState } from 'react';
import '../css/componentscss/Image.css';

const PILImg = ({ src, lowQaulitySrc, alt, className }) => {
  const [imgSrc, setImgSrc] = useState(lowQaulitySrc);
  const [isImgLoaded, setIsImgLoaded] = useState(true);
  const handleImageLoad = (imgSrc) => {
    setImgSrc(imgSrc);
    setIsImgLoaded(false);
  };
  return (
    <img
      src={imgSrc}
      alt={alt}
      className={`object-cover ${className}`} //${sizeClass}
      onLoad={(e) => handleImageLoad(src)}
      style={{
        filter: isImgLoaded ? 'blur(20px)' : 'none',
        transition: 'filter 0.3s ease-out',
      }}
    />
  );
};

export default PILImg;
