// Home Page
import { useEffect, useRef, useState } from 'react';
import Button from '../components/Button';
import HomeSwiper from '../components/HomeSwiper';
import Image from '../components/Image';
import Text from '../components/Text';
import anime from 'animejs';
import '../css/pagescss/HomePage.css';
import { getHomePageData } from '../apiservices/ApiService';
import ClientAndPartnersSlider from '../components/ClientAndPartnersSlider';
import {
  formatNumber,
  getLanguage,
  languageCodes,
  navigateTo,
  navigateToPerticularSection,
  stringToUpperCase,
  userAgent,
} from '../constants/GeneralConstants';
import { Link, useLocation, useParams } from 'react-router-dom';
import { clientUrls } from '../constants/UrlConstants';
import { useSelector } from 'react-redux';
import { AnimateElements } from '../components/SwipeNavigationAnimation';
import { ButtonsUnderSwiper } from '../components/ButtonsUnderSwiper';
import TellUsYourStory from '../components/TellUsYourStory';
import Loader from '../components/Loader';
import Footer from '../components/Footer';
import ArtSiteSEO from '../components/ArtSiteSEO';
import PILImg from '../components/PILImg';
import AnimateOnScroll from '../components/AnimationScroll';
import AOS from 'aos';
import PageNotFound from '../components/PageNotFound';

const leftAnimationElements = [
  { targets: '.crousal-pin', translateX: [15, -11] },
  { targets: '.pin-y', translateX: [15, -11], opacity: [1], scale: [1, 1.2] },
  { targets: '.pin-y0', translateX: [15, -13] },
  { targets: '.pin-y1', translateX: [15, -11], scale: [1, 0.6], opacity: [1] },
  {
    targets: '.pin-y2',
    translateX: [15, -11],
    scale: [1, 0.8],
    opacity: [1, 0.3],
  },
  { targets: '.trial-pin-1', translateX: [10, -15] },
  { targets: '.trial-pin-3', translateX: [10, -15], opacity: [1, 0.1] },
  { targets: '.trial-pin-2', translateX: [0] },
  { targets: '.pin-x2', translateX: [15, -11], scale: [1, 0.6], opacity: [1] },
  {
    targets: '.pin-x0',
    translateX: [15, -11],
    opacity: [1, 0.1],
    scale: [1, 1.7],
  },
  { targets: '.pin-x1', translateX: [15, -11], opacity: [1, 0.3] },
];

const rightAnimationElements = [
  { targets: '.trial-pin-1', translateX: [15, 35] },
  { targets: '.trial-pin-2', translateX: [0, 15], opacity: [1, 0.3] },
  { targets: '.trial-pin-3', translateX: [0] },
  { targets: '.crousal-pin', translateX: [-15, 8], opacity: [1, 0.3] },
  { targets: '.pin-x0', translateX: [-15, 8], scale: [1, 0.5], opacity: 1 },
  { targets: '.pin-x2', translateX: [-15, 11] },
  { targets: '.pin-x1', translateX: [-15, 8], scale: [1, 1.2], opacity: [1] },
  {
    targets: '.pin-y1',
    translateX: [-15, 8],
    scale: [1, 1.4],
    opacity: [1, 0.1],
  },
  { targets: '.pin-y0', translateX: [-15, 8], scale: [1, 0.6], opacity: [1] },
];

let anim = false;

const HomePage = () => {
  // const dispatch = useDispatch();
  const homeSliderRef = useRef(null);
  const teamMembersRef = useRef(null);
  const language = useSelector((state) => state.language.selectedLanguage);
  const [homePageStaticData, setHomePageStaticData] = useState({});
  const [teamIndex, setIndex] = useState(0);
  const [projectsIndex, setProjectsIndex] = useState(0);
  const [teamObj, setTeamObj] = useState({});
  const [loader, setLoader] = useState(true);
  const [shadowImgLoaded, setShadowImgLoaded] = useState(false);
  const { lang } = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);

    getHomePageDetails(getLanguage());
  }, [language]);

  const triggerAnimation = () => {
    var ml4 = {};
    ml4.opacityIn = [0, 1];
    ml4.durationIn = 300;
    ml4.durationOut = 300;
    ml4.delay = 700;

    const animation = anime
      .timeline({ loop: false, autoplay: false })
      .add({
        targets: '.rotate1',
        // opacity: ml4.opacityIn,
        translateY: 0,
        duration: 1000,
        delay: ml4.delay,
      })
      .add({
        targets: '.rotate1',
        translateY: -900,
        easing: 'easeInExpo',
        duration: ml4.durationOut,
        delay: ml4.delay,
      })
      .add({
        targets: '.rotate2',
        opacity: ml4.opacityIn,
        translateY: 0,
        duration: ml4.durationIn,
      })
      .add({
        targets: '.rotate2',
        opacity: 0,
        translateY: -900,
        duration: ml4.durationOut,
        easing: 'easeInExpo',
        delay: ml4.delay,
      })
      .add({
        targets: '.rotate3',
        opacity: ml4.opacityIn,
        translateY: 0,
        duration: ml4.durationIn,
      })
      .add({
        targets: '.rotate3',
        opacity: 0,
        translateY: -900,
        duration: ml4.durationOut,
        easing: 'easeInExpo',
        delay: ml4.delay,
      })
      .add({
        targets: '.rotate4',
        translateY: 0,
        opacity: ml4.opacityIn,
        duration: 500,
      })
      .add({
        targets: '.rotate4',
        opacity: 0,
        translateY: -900,
        duration: ml4.durationOut,
        easing: 'easeInExpo',
        delay: ml4.delay,
      })

      .add({
        targets: '.rotate6',
        opacity: ml4.opacityIn,
        duration: 500,
      })
      .add({
        targets: '.rotate6',
        duration: ml4.durationOut,
        delay: ml4.delay,
      });

    animation.restart();
  };

  const getHomePageDetails = async (language) => {
    try {
      const res = await getHomePageData(language);
      setHomePageStaticData(res.home);
      setTimeout(() => {
        AOS.refresh();
        //triggerAnimation();
      }, 500);
    } catch (err) {
      console.log(err);
    } finally {
      if (anim === false) {
        setTimeout(() => {
          triggerAnimation();
        }, 500);
        anim = true;
      }
      setLoader(false);
      // AOS.refresh()
    }
  };

  const checking = (n) => {
    if (homeSliderRef.current !== null) {
      let s = homeSliderRef.current;
      if (n === 'L') {
        s.swiper.slidePrev();
        AnimateElements(leftAnimationElements);
        setProjectsIndex(s.swiper.realIndex);
      } else if (n === 'R') {
        s.swiper.slideNext();
        AnimateElements(rightAnimationElements);
        setProjectsIndex(s.swiper.realIndex);
      }
    }
  };

  const leftTeamNavigation = (index) => {
    let i;
    if (index > 0) {
      i = index - 1;
    } else {
      i = homePageStaticData?.teamMembers.length - 1;
    }
    const { userImage, userJobTitle, userName } =
      homePageStaticData?.teamMembers[i];
    setTeamObj({ image: userImage, userJobTitle, userName });
    setIndex(i);
    AnimateElements(leftAnimationElements);
  };

  const rightTeamNavigation = (index) => {
    let i;
    if (index < homePageStaticData?.teamMembers.length - 1) {
      i = index + 1;
    } else {
      i = 0;
    }
    const { userImage, userJobTitle, userName } =
      homePageStaticData?.teamMembers[i];
    setTeamObj({ image: userImage, userJobTitle, userName });
    setIndex(i);
    AnimateElements(rightAnimationElements);
  };

  const [imageWidth, setImageWidth] = useState(0);
  const swiperRefDiv = useRef(null);
  if (lang && !languageCodes.includes(lang)) {
    return <PageNotFound />;
  }
  useEffect(() => {
    let prevWidth = window.innerWidth;

    const handleResize = () => {
      setTimeout(
        () => {
          if (swiperRefDiv.current) {
            const currentWidth = window.innerWidth;
            if (currentWidth !== prevWidth) {
              prevWidth = currentWidth;
              const centerImage = swiperRefDiv.current.querySelector(
                '.swiper-slide-active img',
              );
              setImageWidth(centerImage.clientWidth);
            }
          }
        },
        userAgent.indexOf('Mac') !== -1 ? 1000 : 300,
      );
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [imageWidth, homePageStaticData]);
  const arrowOffset = 16;
  return (
    <>
      <ArtSiteSEO
        title={homePageStaticData?.seo?.title}
        description={homePageStaticData?.seo?.description}
        url={getLanguage() !== 'en' ? `/${getLanguage()}/` : `${clientUrls.home}`}
      />
      {loader ? (
        <Loader />
      ) : (
        <>
          <div className="lg:relative">
            {homePageStaticData?.projectsData && (
              <section id="homepage" className="pt-16 lg:pt-24">
                {/* Home Swiper section starts here */}
                <div className="heading">
                  <div className="home-carousel">
                    {/* <span className="leftshadow"></span> */}
                    <div className="sliderShadow absolute top-[3%] w-[65%] h-[30%] left-0 hidden lg:block z-[-1]">
                      <img src="/images/home-hero-shadow.png" alt="ptw art" />
                    </div>
                    <AnimateOnScroll animation="fade-up" duration={1000}>
                      <Text
                        className={
                          'font-medium vs:text-[11px] xs:text-[1rem] md:text-2xl vs:tracking-[2px] xs:tracking-[3px]'
                        }
                        title={stringToUpperCase(
                          homePageStaticData?.weBringGamesToLifeText
                            ? homePageStaticData?.weBringGamesToLifeText
                            : '',
                        )}
                        titleTag="h3"
                      />
                    </AnimateOnScroll>
                    <section className="text-center py-3">
                      <div className="heading-home">
                        <h2 className="font-extrabold lg:text-[60px] md:text-6xl 2xl:text-[98px] xl:text-[80px] vs:text-[1.25rem] xs:text-[2rem] xs:leading-[2rem] vs:leading-[1.25rem] lg:leading-[1] uppercase overflow-hidden">
                          <AnimateOnScroll animation="fade-up" duration={1000}>
                            <div className="overflow-hidden">
                              <Text
                                className="block lg:mr-0 mr-12 "
                                title={stringToUpperCase(
                                  homePageStaticData?.bringingText
                                    ? homePageStaticData?.bringingText
                                    : ``,
                                )}
                                titleTag="span"
                              />
                            </div>
                          </AnimateOnScroll>
                          <div className={`flex lg:justify-center overflow-hidden ${getLanguage() === 'ja' ? 'ja-ml' : ''}`}>
                            <AnimateOnScroll
                              animation="fade-up"
                              duration={1000}
                            >
                              <Text
                                className={`block vs:ml-[5rem] ${getLanguage() === 'fr-ca' || getLanguage() === 'ko' ? 'xs:ml-[3rem] sm:ml-[12rem]' : 'xs:ml-[7rem] sm:ml-[17rem]'} lg:ml-0`}
                                title={stringToUpperCase(
                                  homePageStaticData?.your
                                    ? homePageStaticData?.your
                                    : '',
                                )}
                                // title="Your"
                                titleTag="span"
                              />
                            </AnimateOnScroll>
                            <AnimateOnScroll
                              animation="fade-up"
                              duration={1000}
                            >
                              <div className="pl-2 lg:pl-4 overflow-hidden">
                                <span className="animetext_rotate text-nowrap">
                                  <span className="animetext inline-block rotate1 absolute">
                                    {stringToUpperCase(
                                      homePageStaticData?.vision
                                        ? homePageStaticData?.vision
                                        : '',
                                    )}
                                  </span>
                                  <span className="animetext inline-block opacity-0 rotate2 absolute">
                                    {stringToUpperCase(
                                      homePageStaticData?.art
                                        ? homePageStaticData?.art
                                        : '',
                                    )}
                                  </span>
                                  <span className="animetext inline-block opacity-0 rotate3 absolute">
                                    {stringToUpperCase(
                                      homePageStaticData?.idea
                                        ? homePageStaticData?.idea
                                        : '',
                                    )}
                                  </span>
                                  <span className="animetext inline-block opacity-0 rotate4 absolute">
                                    {stringToUpperCase(
                                      homePageStaticData?.game
                                        ? homePageStaticData?.game
                                        : '',
                                    )}
                                  </span>
                                  {/* <span className="animetext rotate5 absolute overflow-hidden opacity-0">
                                    {stringToUpperCase(
                                      homePageStaticData?.characters
                                        ? homePageStaticData?.characters
                                        : '',
                                    )}
                                  </span> */}
                                  <span className="animetext inline-block opacity-0 rotate6 absolute ">
                                    {stringToUpperCase(
                                      homePageStaticData?.story
                                        ? homePageStaticData?.story
                                        : '',
                                    )}
                                  </span>
                                </span>
                              </div>
                            </AnimateOnScroll>
                          </div>
                          <AnimateOnScroll animation="fade-up" duration={1000}>
                            <div className="overflow-hidden">
                              <Text
                                className="block overflow-hidden vs:pr-8 xs:pr-0"
                                title={stringToUpperCase(
                                  homePageStaticData?.toTheWorldText
                                    ? homePageStaticData?.toTheWorldText
                                    : '',
                                )}
                                titleTag="span"
                              />
                            </div>
                          </AnimateOnScroll>
                        </h2>
                      </div>
                    </section>
                  </div>
                  {/* {homePageStaticData?.projectsData && ( */}
                  <>
                    <div className="home-slider mt-4 mb-5 ">
                      <div className="mr-5 ml-5 relative">
                        <div className="sec-1-slideup slideup absolute left-[-15rem] lg:left-[-14.5rem] top-[60%] z-[11] w-[30rem]">
                          <AnimateOnScroll
                            animation={'fade-up'}
                            duration={1000}
                          >
                            <div className=" hidden sm:block">
                              <div className="sidelinecontent flex items-center justify-center z-[11] gap-x-4 rotate-90	will-change-transform">
                                <span className="outlinedot w-[7px] h-[7px] min-w-[7px] border-solid border border-white rounded-[50%]"></span>
                                <span className="filldot w-[7px] h-[7px] border border-solid border-[#ff2e29] bg-[#ff2e29] rounded-[50%]"></span>
                                <p className="uppercase text-[16px] lg:text-[20px] whitespace-nowrap">
                                  {stringToUpperCase(
                                    homePageStaticData?.featuredProjects
                                      ? homePageStaticData?.featuredProjects
                                      : '',
                                  )}
                                </p>
                                <span className="line w-[4rem] h-[1px] bg-white opacity-100	"></span>
                                <span className="outlinedot w-[7px] h-[7px] min-w-[7px] border-solid border border-white rounded-[50%]"></span>
                                <span className="outlinedot w-[7px] h-[7px] min-w-[7px] border-solid border border-white rounded-[50%]"></span>
                              </div>
                            </div>
                          </AnimateOnScroll>
                        </div>
                        <div className="shadows-slider hidden lg:block">
                          <img
                            alt=""
                            className="leftShadow-slider"
                            src="/images/left-slider-shadow.png"
                            onLoad={() => {
                              setShadowImgLoaded(true);
                            }}
                          />
                          <Image
                            alt=""
                            className="rightShadow-slider"
                            src="/images/right-slider-shadow.png"
                          />
                        </div>
                        <div ref={swiperRefDiv} className="mx-2 lg:mx-0">
                          {shadowImgLoaded && (
                            <HomeSwiper
                              slides={
                                homePageStaticData?.projectsData
                                  ? homePageStaticData?.projectsData
                                  : []
                              }
                              sliderRef={homeSliderRef}
                              setImageWidth={setImageWidth}
                            />
                          )}
                          {imageWidth > 0 && (
                            <div
                              className="swiper-button-prev"
                              style={{
                                left: `calc(50% - ${imageWidth / 2 + arrowOffset}px)`,
                                top: '50%',
                                transform: 'translateY(-50%)',
                                position: 'absolute',
                                zIndex: '10',
                              }}
                            >
                              <Button
                                className={
                                  'p-3 rounded-full border z-10 prevarrow bg-[#000000c7] hover:bg-red-700'
                                }
                                onClick={(e) => {
                                  checking('L');
                                }}
                                type="button"
                              >
                                <svg
                                  className="h-5 w-5"
                                  viewBox="0 0 16 11"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    fillRule="evenodd"
                                    strokeWidth=" 0px"
                                    fill="#fefbf7"
                                    className="cls-1"
                                    d="M15.8,5.7v-.5H1.9l3.8-3.8.3-.3-.6-.6-.3.3L.4,5.3l-.3.3.3.3,4.6,4.6.3.3.6-.6-.3-.3-3.8-3.8h13.1v3.3h.9v-3.7Z"
                                  />
                                </svg>
                              </Button>
                            </div>
                          )}
                          {imageWidth > 0 && (
                            <div
                              className="swiper-button-next"
                              style={{
                                right: `calc(50% - ${imageWidth / 2 + arrowOffset}px)`,
                                top: '50%',
                                transform: 'translateY(-50%)',
                                position: 'absolute',
                                zIndex: '10',
                              }}
                            >
                              <Button
                                className={
                                  'p-3 rounded-full border z-10 nextarrow bg-[#000000c7] hover:bg-red-700'
                                }
                                onClick={(e) => checking('R')}
                                type="button"
                              >
                                <svg
                                  className="h-5 w-5"
                                  viewBox="0 0 16 11"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M0.171875 5.27543L0.171875 5.77543L0.671875 5.77543L13.4612 5.77543L9.89449 9.34217L9.54094 9.69573L10.248 10.4028L10.6016 10.0493L15.0219 5.62895L15.3755 5.2754L15.0219 4.92184L10.6016 0.501512L10.248 0.147959L9.54094 0.855066L9.89449 1.20862L13.4613 4.77543L1.17188 4.77543L1.17188 2.1127L1.17188 1.6127L0.171875 1.6127L0.171875 2.1127L0.171875 5.27543Z"
                                    fill="white"
                                  ></path>
                                </svg>
                              </Button>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="viewallprojects">
                      <Link
                        to={navigateTo(clientUrls.projects)}
                        className="flex items-center cursor-pointer z-10"
                      >
                        <Text
                          className={
                            'text-orange px-1 py-1 text-center font-medium text-[12px] lg:text-[15px] tracking-widest'
                          }
                          title={stringToUpperCase(
                            homePageStaticData?.viewAllProjectsText
                              ? homePageStaticData?.viewAllProjectsText
                              : 'VIEW ALL PROJECTS',
                          )}
                          titleTag="p"
                        />
                        <svg
                          width={15}
                          height={11}
                          viewBox="0 0 12 8"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="ml-2"
                        >
                          <path
                            d="M11.3536 4.35355C11.5488 4.15829 11.5488 3.84171 11.3536 3.64645L8.17157 0.464466C7.97631 0.269204 7.65973 0.269204 7.46447 0.464466C7.2692 0.659728 7.2692 0.976311 7.46447 1.17157L10.2929 4L7.46447 6.82843C7.2692 7.02369 7.2692 7.34027 7.46447 7.53553C7.65973 7.7308 7.97631 7.7308 8.17157 7.53553L11.3536 4.35355ZM0.5 4.5H11V3.5H0.5V4.5Z"
                            fill="#FF2E29"
                          ></path>
                        </svg>
                      </Link>
                    </div>
                    <div className="IndicatorsPosition">
                      <div className="crousal-container">
                        <span className="project-wrap crousal-left-count">
                          <span className="project-animetext">
                            <span className="project-number project-letter1 w-[19px] block">
                              {formatNumber(projectsIndex + 1)}
                            </span>
                          </span>
                        </span>

                        <ButtonsUnderSwiper />
                        <span className="project-wrap crousal-right-count">
                          <span className="project-animetext">
                            <span className="project-number project-letter1">
                              {homePageStaticData?.projectsData?.length}
                            </span>
                          </span>
                        </span>
                      </div>
                    </div>
                  </>
                  {/* )} */}
                </div>
                {/* Home Swiper section ends here */}

                {/* at 1518 heading and para section starts here  */}
                <div className="mt-20 md:mt-20 w-[80%] lg:w-[75%] xl:w-[75%] 2xl:w-[75%] 3xl:w-[65%] mx-auto">
                  <AnimateOnScroll animation="fade-up" duration={1000}>
                    <Text
                      className="orange-heading font-bold text-[16px] lg:text-[42px]"
                      title={stringToUpperCase(
                        homePageStaticData?.section2HeaderText
                          ? homePageStaticData?.section2HeaderText
                          : 'AT 1518, IT’S ALL WORK AND ALL PLAY, NONSTOP',
                      )}
                      titleTag="h3"
                    />
                  </AnimateOnScroll>

                  <div className="pb-8">
                    <Text
                      className="text-[14px] lg:text-[20px]"
                      title={
                        homePageStaticData?.section2SubText
                          ? homePageStaticData?.section2SubText
                          : ''
                      }
                      titleTag="p"
                    />
                  </div>
                </div>
                {/* at 1518 heading and para section ends here  */}

                {/* Count section starts here  */}

                <div className="mb-10 md:mb-20 w-[80%] lg:w-[75%] xl:w-[75%] 2xl:w-[75%] 3xl:w-[65%] mx-auto">
                  <div class="grid grid-cols-3 md:grid-cols-5 lg:flex flex-nowrap justify-between gap-y-[1rem] gap-x-[1rem] pb-4 lg:pb-0 text-center">
                    <div className='pb-4 lg:pb-0 '>
                      <Text
                        className="orange-heading font-bold text-[16px] lg:text-[42px]"
                        title={
                          homePageStaticData?.statsArtistsValue
                            ? homePageStaticData?.statsArtistsValue
                            : ''
                        }
                        titleTag="h3"
                      />
                      <Text
                        className="text-[14px] lg:text-[20px] uppercase"
                        title={
                          homePageStaticData?.statsArtistsLabel
                            ? homePageStaticData?.statsArtistsLabel
                            : ''
                        }
                        titleTag="p"
                      />
                    </div>
                    <div className='pb-4 lg:pb-0'>
                      <Text
                        className="orange-heading font-bold text-[16px] lg:text-[42px]"
                        title={
                          homePageStaticData?.statsTitlesValue
                            ? homePageStaticData?.statsTitlesValue
                            : ''
                        }
                        titleTag="h3"
                      />
                      <Text
                        className="text-[14px] lg:text-[20px] uppercase"
                        title={
                          homePageStaticData?.statsTitlesLabel
                            ? homePageStaticData?.statsTitlesLabel
                            : ''
                        }
                        titleTag="p"
                      />
                    </div>
                    <div className='pb-4 lg:pb-0'>
                      <Text
                        className="orange-heading font-bold text-[16px] lg:text-[42px]"
                        title={
                          homePageStaticData?.statsPartnersValue
                            ? homePageStaticData?.statsPartnersValue
                            : ''
                        }
                        titleTag="h3"
                      />
                      <Text
                        className="text-[14px] lg:text-[20px] uppercase"
                        title={
                          homePageStaticData?.statsPartnersLabel
                            ? homePageStaticData?.statsPartnersLabel
                            : ''
                        }
                        titleTag="p"
                      />
                    </div>
                    <div className='pb-4 lg:pb-0'>
                      <Text
                        className="orange-heading font-bold text-[16px] lg:text-[42px]"
                        title={
                          homePageStaticData?.statsCountriesValue
                            ? homePageStaticData?.statsCountriesValue
                            : ''
                        }
                        titleTag="h3"
                      />
                      <Text
                        className="text-[14px] lg:text-[20px] uppercase"
                        title={
                          homePageStaticData?.statsCountriesLabel
                            ? homePageStaticData?.statsCountriesLabel
                            : ''
                        }
                        titleTag="p"
                      />
                    </div>
                    <div className='pb-4 lg:pb-0'>
                      <Text
                        className="orange-heading font-bold text-[16px] lg:text-[42px]"
                        title={
                          homePageStaticData?.statsHQ
                            ? homePageStaticData?.statsHQ
                            : ''
                        }
                        titleTag="h3"
                      />
                      <Text
                        className={`text-[14px] lg:text-[20px] uppercase hidden lg:block ${getLanguage() === 'fr-ca' || getLanguage() === 'ja' || getLanguage() === 'ko' ? 'mt-[2.4rem]' : 'mt-0'}`}
                        // className="text-[14px] lg:text-[20px] uppercase hidden lg:block"
                        title={
                          homePageStaticData?.statsHQLocation
                            ? homePageStaticData?.statsHQLocation
                            : ''
                        }
                        titleTag="p"
                      />
                      {/* mobile para  */}
                      {/* <>{
                      homePageStaticData?.statsHQLocation
                      ? homePageStaticData?.statsHQLocation
                        : ''
                    }
                    </> */}
                      <Text
                        className={`text-[14px] lg:text-[20px] uppercase block lg:hidden w-max sm:w-auto mx-auto ${getLanguage() === 'fr-ca' ? 'mt-[0.5rem] md:mt-0' : 'mt-0'}`}
                        // className="text-[14px] lg:text-[20px] uppercase block lg:hidden w-max sm:w-auto mx-auto" 
                        title={
                          homePageStaticData?.statsHQLocation
                            ? `${homePageStaticData?.statsHQLocation.split(',')[0]}${homePageStaticData?.statsHQLocation.split(',').length > 1 ? "," : ""}`
                            : ''
                        }
                        titleTag="p"
                      />
                      <Text
                        className="text-[14px] lg:text-[20px] uppercase block lg:hidden w-max sm:w-auto mx-auto "
                        title={
                          homePageStaticData?.statsHQLocation
                            ? homePageStaticData?.statsHQLocation.split(',')[1]
                            : ''
                        }
                        titleTag="p"
                      />

                    </div>

                  </div>

                </div>
                {/* Count section ends here  */}

                {/* Clients logos section starts here  */}

                <div className="clients relative">
                  <div className="our-clents">
                    <div className="clients_head">
                      <AnimateOnScroll animation="fade-up" duration={1000}>
                        <Text
                          className="hidden lg:block"
                          title={stringToUpperCase(
                            homePageStaticData?.ourClientsText
                              ? homePageStaticData?.ourClientsText
                              : '',
                          )}
                          titleTag="h1"
                        />
                      </AnimateOnScroll>
                      {homePageStaticData?.ourClientsText
                        ?.split(' ')
                        .map((el, i) => (
                          <Text
                            key={i}
                            className="block lg:hidden mobile-client"
                            title={stringToUpperCase(
                              el ? el : i === 0 ? 'OUR' : 'CLIENTS',
                            )}
                            titleTag="h2"
                          />
                        ))}
                      <div className="sec-1-slideup slideup absolute left-[-14rem] lg:left-[-13.3rem] top-[60%] z-[11] w-[30rem]">
                        <AnimateOnScroll animation={'fade-up'} duration={1000}>
                          <div className="sidelinecontent flex items-center justify-center z-[11] gap-x-4 rotate-90	will-change-transform">
                            <span className="outlinedot w-[7px] h-[7px] min-w-[7px] border-solid border border-white rounded-[50%]"></span>
                            <span className="filldot w-[7px] h-[7px] border border-solid border-[#ff2e29] bg-[#ff2e29] rounded-[50%]"></span>
                            <p className="uppercase text-[16px] lg:text-[20px] whitespace-nowrap">
                              {stringToUpperCase(
                                homePageStaticData?.ourClientsText
                                  ? homePageStaticData?.ourClientsText
                                  : '',
                              )}
                            </p>
                            <span className="line w-[4rem] h-[1px] bg-white opacity-100	"></span>
                            <span className="outlinedot w-[7px] h-[7px] min-w-[7px] border-solid border border-white rounded-[50%]"></span>
                            <span className="outlinedot w-[7px] h-[7px] min-w-[7px] border-solid border border-white rounded-[50%]"></span>
                          </div>
                        </AnimateOnScroll>
                      </div>
                      <div className="desktop-client-logos hidden lg:block p-5 mt-8">
                        <div className="shadows">
                          <Image
                            alt=""
                            className="leftShadow"
                            src="/images/home-left-shadow.png"
                          />
                          <Image
                            alt=""
                            className="rightShadow"
                            src="/images/home-right-shadow.png"
                          />
                        </div>
                        <div>
                          <ClientAndPartnersSlider
                            slides={
                              homePageStaticData?.clientsLogo
                                ? homePageStaticData?.clientsLogo
                                : []
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="mobile-client-logos block lg:hidden p-5 absolute top-[-1%] md:top-[-23%] lg:top-[-17%] sm:top-[-17%] width-fill mobile-top w-full">
                    <div className="shadows">
                      <Image
                        alt=""
                        className="leftShadow w-5rem"
                        src="/images/home-left-shadow.png"
                      />
                      <Image
                        alt=""
                        className="rightShadow w-5rem"
                        src="/images/home-right-shadow.png"
                      />
                    </div>
                    <div>
                      <ClientAndPartnersSlider
                        slides={
                          homePageStaticData?.clientsLogo
                            ? homePageStaticData?.clientsLogo
                            : []
                        }
                      />
                    </div>
                  </div>

                  <div className="w-[85%] lg:w-[65%] xl:w-[75%] 2xl:w-[65%] mx-auto my-14 lg:mb-24">
                    <Text
                      className="text-left md:text-center text-[14px] lg:text-[20px]"
                      title={
                        homePageStaticData?.ourClientsSubText
                          ? homePageStaticData?.ourClientsSubText
                          : ''
                      }
                      titleTag="p"
                    />
                  </div>
                </div>

                <div className="border_line hidden lg:block">
                  <div className="shadows">
                    <Image
                      src="/images/home-left-shadow.png"
                      alt=""
                      className="object-cover leftShadow"
                    />
                    <Image
                      src="/images/home-right-shadow.png"
                      alt=""
                      className="object-cover rightShadow"
                    />
                  </div>
                  <Image src="/images/line-client.png" alt={'Ptw art'} />
                </div>
                <div className="tell-top-line lg:hidden block"></div>
                <div
                  ref={teamMembersRef}
                  className="meet-team pb-20 text-center px-0 lg:px-16 lg:pt-20 lg:pb-0"
                >
                  <div className="flex items-center justify-center px-4">
                    <AnimateOnScroll animation="fade-up" duration={1000}>
                      <Text
                        className="text-white text-2xl lg:text-7xl font-extrabold pb-10 md:pb-10 "
                        title={stringToUpperCase(
                          homePageStaticData?.meetTheTeamText
                            ? homePageStaticData?.meetTheTeamText
                            : '',
                        )}
                        titleTag="h2"
                      />
                    </AnimateOnScroll>
                    <div className="pb-10 lg:pb-10 lg:w-auto lg:h-auto mt-2">
                      <div
                        className="cursor-pointer"
                        onClick={(e) =>
                          navigateToPerticularSection(teamMembersRef)
                        }
                      >
                        <Image
                          src="/images/arrow-team.png"
                          alt="Image"
                          className="h-auto w-5 lg:w-auto mt-4 lg:ml-4 ml-2"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className="relative hidden lg:block w-[90%] mr-auto ml-auto"
                    id="team"
                  >
                    <div className="sec-1-slideup slideup absolute left-[-14rem] md:left-[-20rem] lg:left-[-20rem] top-[20%] z-[11] w-[30rem]">
                      <AnimateOnScroll animation={'fade-up'} duration={1000}>
                        <div className="sidelinecontent flex items-center justify-center z-[11] gap-x-4 rotate-90	will-change-transform">
                          <span className="outlinedot w-[7px] h-[7px] min-w-[7px] border-solid border border-white rounded-[50%]"></span>
                          <span className="filldot w-[7px] h-[7px] border border-solid border-[#ff2e29] bg-[#ff2e29] rounded-[50%]"></span>
                          <p className="uppercase text-[16px] lg:text-[20px] whitespace-nowrap">
                            {stringToUpperCase(
                              homePageStaticData?.whoWeAre
                                ? homePageStaticData?.whoWeAre
                                : '',
                            )}
                          </p>
                          <span className="line w-[4rem] h-[1px] bg-white opacity-100	"></span>
                          <span className="outlinedot w-[7px] h-[7px] min-w-[7px] border-solid border border-white rounded-[50%]"></span>
                          <span className="outlinedot w-[7px] h-[7px] min-w-[7px] border-solid border border-white rounded-[50%]"></span>
                        </div>
                      </AnimateOnScroll>
                    </div>
                    <div className="flex flex-wrap ">
                      {homePageStaticData?.teamMembers?.length > 0
                        ? homePageStaticData?.teamMembers.map((team, index) => {
                          return (
                            <div
                              className="w-full md:w-1/4 p-4 mt-5 flex justify-center"
                              key={index}
                            >
                              <div className="jon-title">
                                <AnimateOnScroll animation="zoom-in">
                                  <Image
                                    className="lg:p-5"
                                    src={team.userImage}
                                  />
                                  {/* <PILImg
                                      src={team.userImage}
                                      lowQaulitySrc={
                                        team?.userImageLowQuality
                                          ? team?.userImageLowQuality
                                          : team?.userImage
                                      }
                                      className="lg:p-5"
                                    /> */}
                                </AnimateOnScroll>
                                <Text
                                  className="text-left pt-2 lg:px-5 text-orange font-medium uppercase text-[14px] text-balance"
                                  title={team.userJobTitle}
                                  titleTag="p"
                                />
                                <Text
                                  className="text-left pt-1 lg:px-5 font-extrabold uppercase text-lg  job-head"
                                  title={team.userName}
                                  titleTag="h2"
                                />
                              </div>
                            </div>
                          );
                        })
                        : null}
                    </div>
                  </div>

                  <div className="mobile-team block lg:hidden relative">
                    <div className="sec-1-slideup slideup absolute left-[-14rem] lg:left-[-22.2rem] top-[30%] z-[11] w-[30rem]">
                      <AnimateOnScroll animation={'fade-up'} duration={1000}>
                        <div className="sidelinecontent flex items-center justify-center z-[11] gap-x-4 rotate-90	will-change-transform">
                          <span className="outlinedot w-[7px] h-[7px] min-w-[7px] border-solid border border-white rounded-[50%]"></span>
                          <span className="filldot w-[7px] h-[7px] border border-solid border-[#ff2e29] bg-[#ff2e29] rounded-[50%]"></span>
                          <p className="uppercase text-[16px] lg:text-[20px] whitespace-nowrap">
                            {stringToUpperCase(
                              homePageStaticData?.whoWeAre
                                ? homePageStaticData?.whoWeAre
                                : '',
                            )}
                          </p>
                          <span className="line w-[4rem] h-[1px] bg-white opacity-100	"></span>
                          <span className="outlinedot w-[7px] h-[7px] min-w-[7px] border-solid border border-white rounded-[50%]"></span>
                          <span className="outlinedot w-[7px] h-[7px] min-w-[7px] border-solid border border-white rounded-[50%]"></span>
                        </div>
                      </AnimateOnScroll>
                    </div>
                    <div className="flex items-center justify-center mx-9">
                      {homePageStaticData?.teamMembers?.length > 0 ? (
                        <div className="team-members">
                          <Image
                            className="vs:w-[16rem] xs:w-full mx-auto"
                            src={
                              teamObj?.image
                                ? teamObj?.image
                                : homePageStaticData?.teamMembers[0].userImage
                            }
                          />
                          <Text
                            className="text-left pt-4 text-orange font-bold uppercase text-[14px] vs:w-[16rem] xs:w-full mx-auto"
                            title={
                              teamObj?.userJobTitle
                                ? teamObj?.userJobTitle
                                : homePageStaticData?.teamMembers[0]
                                  .userJobTitle
                            }
                            titleTag="p"
                          />
                          <Text
                            className="text-left pt-1 font-extrabold uppercase text-lg job-head vs:w-[16rem] xs:w-[26ch] mx-auto"
                            title={
                              teamObj?.userName
                                ? teamObj?.userName
                                : homePageStaticData?.teamMembers[0].userName
                            }
                            titleTag="h2"
                          />
                        </div>
                      ) : null}
                    </div>
                    <div className="IndicatorsPosition">
                      <div className="crousal-container ">
                        <Button
                          type="button"
                          onClick={(e) => leftTeamNavigation(teamIndex)}
                          className="p-2 rounded-full border z-10 mr-3 bg-white"
                        >
                          <svg
                            className="h-6 w-6"
                            viewBox="0 0 16 11"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              strokeWidth=" 0px"
                              fill="#000"
                              className="cls-1"
                              d="M15.8,5.7v-.5H1.9l3.8-3.8.3-.3-.6-.6-.3.3L.4,5.3l-.3.3.3.3,4.6,4.6.3.3.6-.6-.3-.3-3.8-3.8h13.1v3.3h.9v-3.7Z"
                            ></path>
                          </svg>
                        </Button>
                        <span className="project-wrap crousal-left-count">
                          <span className="project-animetext">
                            <span className="project-number project-letter1">
                              {formatNumber(teamIndex + 1)}
                            </span>
                          </span>
                        </span>
                        <div className="crousal-container">
                          <ButtonsUnderSwiper />
                        </div>
                        <span className="crousal-right-count">
                          {formatNumber(
                            homePageStaticData?.teamMembers?.length
                              ? homePageStaticData?.teamMembers?.length
                              : 1,
                          )}
                        </span>
                        <Button
                          type="button"
                          onClick={(e) => rightTeamNavigation(teamIndex)}
                          className="p-2 rounded-full border z-10 ml-3 bg-white"
                        >
                          <svg
                            className="h-6 w-6"
                            viewBox="0 0 16 11"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M0.171875 5.27543L0.171875 5.77543L0.671875 5.77543L13.4612 5.77543L9.89449 9.34217L9.54094 9.69573L10.248 10.4028L10.6016 10.0493L15.0219 5.62895L15.3755 5.2754L15.0219 4.92184L10.6016 0.501512L10.248 0.147959L9.54094 0.855066L9.89449 1.20862L13.4613 4.77543L1.17188 4.77543L1.17188 2.1127L1.17188 1.6127L0.171875 1.6127L0.171875 2.1127L0.171875 5.27543Z"
                              fill="#000"
                            ></path>
                          </svg>
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            )}
            <div className="absolute h-[45%] w-[55%] bottom-[-12%] right-[0%] -z-10 hidden lg:block">
              <img className="w-full h-full" src="/images/tell_us_shadow.png" />
            </div>
            <TellUsYourStory />
            <div className="absolute w-full bottom-[-7%] right-[0%] -z-10 block lg:hidden">
              <img
                className="w-full h-full"
                src="/images/tell-shadow-mobile.png"
              />
            </div>
          </div>

          <Footer />
        </>
      )}
    </>
  );
};

export default HomePage;
