// this component is used to render clients logos and parterns logos

import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import '../css/componentscss/ClientAndPartnersSlider.css';
import Image from './Image';

const options = {
  loop: true,
  autoplay: true,
  autoplayHoverPause: true,
  autoplayTimeout: 3000,
  autoplaySpeed: 3000,
  margin: 10,
  nav: false,
  responsive: {
    0: {
      items: 2,
    },
    500: {
      items: 3,
    },
    700: {
      items: 4,
    },
    1000: {
      items: 7,
    },
    // 992: {
    //   items: 3
    // },
    // 1200: {
    //   items: 9
    // },
  },
};

const ClientAndPartnersSlider = ({ slides }) => {
  return (
    <>
      <OwlCarousel className="clientsslider" id="clients-home" {...options}>
        {slides.map((image, index) => {
          return (
            <div
              className="item client-logo flex items-center justify-center h-full"
              key={index}
            >
              <Image src={image.clientLogo} alt={image.clientName} />
            </div>
          );
        })}
      </OwlCarousel>
    </>
  );
};

export default ClientAndPartnersSlider;
