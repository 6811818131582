// here we are calling API's
import { apis } from '../constants/APIConstants';
import ApiInstance from './AxiosInstance';

const DEV_API_BASE_URL = '/api/v1/';

export const getNavigationBarData = async (language = 'en') => {
  try {
    const response = await ApiInstance.get(
      `${DEV_API_BASE_URL}${language}${apis.navigationBarAndFooter}`,
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching API data:', error);
    throw error;
  }
};

export const getHomePageData = async (language = 'en') => {
  try {
    const response = await ApiInstance.get(
      `${DEV_API_BASE_URL}${language}${apis.homePageData}`,
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching API data:', error);
    throw error;
  }
};

export const postContactFormData = async (reqBody) => {
  try {
    const response = await ApiInstance.post(
      `${DEV_API_BASE_URL}${apis.postContactData}`,
      reqBody,
    );

    return response.data;
  } catch (error) {
    console.log('error fetching api data:', error);
    throw error;
  }
};
export const getProjectDetailData = async (language = 'en', projectName) => {
  try {
    const response = await ApiInstance.get(
      `${DEV_API_BASE_URL}${language}${apis.projectsData}/${projectName}`,
    );
    return response?.data;
  } catch (error) {
    console.error('Error fetching API data:', error);

    return error?.response?.data?.data;
  }
};
export const getProjectListStaticData = async (language = 'en') => {
  try {
    const response = await ApiInstance.get(
      `${DEV_API_BASE_URL}${language}${apis.projectsListStaticData}`,
    );
    return response?.data;
  } catch (error) {
    console.error('Error fetching API data:', error);

    return error?.response?.data?.data;
  }
};
export const getContactData = async (language = 'en') => {
  try {
    const response = await ApiInstance.get(
      `${DEV_API_BASE_URL}${language}${apis.contactData}`,
    );
    return response?.data;
  } catch (error) {
    console.error('Error fetching API data:', error);

    return error?.response?.data?.data;
  }
};

// Portfolio Component API Calls

export const callNDAContent = async (language = 'en') => {
  try {
    const response = ApiInstance.get(
      `${DEV_API_BASE_URL}${language}${apis.ndaPopUPContent}`,
    );
    return response;
  } catch (error) {
    console.log('error fetching api data:', error);
    throw error;
  }
};

export const submitNdaDetails = async (obj) => {
  try {
    const response = ApiInstance.post(
      `${DEV_API_BASE_URL}${apis.ndaSubmission}`,
      obj,
    );
    return response;
  } catch (error) {
    console.log('error fetching api data:', error);
    throw error;
  }
};

export const portfolioStaticPageData = async (language = 'en') => {
  try {
    const response = ApiInstance.get(
      `${DEV_API_BASE_URL}${language}${apis.portfolioStaticData}`,
    );
    return response;
  } catch (error) {
    console.log('error fetching api data:', error);
    throw error;
  }
};

export const getPortfolioCategories = async (obj) => {
  try {
    const response = ApiInstance.post(
      `${DEV_API_BASE_URL}${apis.portfolioCategories}`,
      obj,
    );
    return response;
  } catch (error) {
    console.log('error fetching api data:', error);
    throw error;
  }
};

export const getPortfolioCategoriesAndAssets = async (obj) => {
  try {
    const response = ApiInstance.post(
      `${DEV_API_BASE_URL}${apis.portfolioCategoriesAndData}`,
      obj,
    );
    return response;
  } catch (error) {
    console.log('error fetching api data:', error);
    throw error;
  }
};
export const getFeturedProjectData = async (language = 'en') => {
  try {
    const response = await ApiInstance.get(
      `${DEV_API_BASE_URL}${language}${apis.featuredProject}`,
    );
    return response?.data;
  } catch (error) {
    console.error('Error fetching API data:', error);

    return error?.response?.data?.data;
  }
};
export const getProjectFiltersData = async (language = 'en') => {
  try {
    const response = await ApiInstance.get(
      `${DEV_API_BASE_URL}${language}${apis.projectFilters}`,
    );
    return response?.data;
  } catch (error) {
    console.error('Error fetching API data:', error);

    return error?.response?.data?.data;
  }
};
export const getProjectListingData = async (obj) => {
  try {
    const response = ApiInstance.post(
      `${DEV_API_BASE_URL}${apis.projectsListingData}`,
      obj,
    );
    return response;
  } catch (error) {
    console.log('error fetching api data:', error);
    return error;
  }
};

export const getServicesDataAPI1 = async (language) => {
  try {
    const response = ApiInstance.get(
      `${DEV_API_BASE_URL}${language}${apis.services}`,
    );
    return response;
  } catch (error) {
    console.log('error fetching api data:', error);
    throw error;
  }
};
export const getPageNotFoundData = async (language = 'en') => {
  try {
    const response = await ApiInstance.get(
      `${DEV_API_BASE_URL}${language}${apis.pageNotFound}`,
    );
    return response?.data;
  } catch (error) {
    console.error('Error fetching API data:', error);

    return error?.response?.data?.data;
  }
};
