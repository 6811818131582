import { useLocation, useNavigate, useParams } from 'react-router-dom';
import '../../css/pagescss/SubPortfolio.css';
import { useEffect, useRef, useState } from 'react';
import Cookies from 'js-cookie';
import { useSelector } from 'react-redux';
import Image from '../../components/Image';
import Text from '../../components/Text';
import {
  getLanguage,
  navigateTo,
  navigateToPerticularSection,
  scrollToTop,
  userAgent,
} from '../../constants/GeneralConstants';
import {
  getPortfolioCategories,
  getPortfolioCategoriesAndAssets,
  portfolioStaticPageData,
} from '../../apiservices/ApiService';
import { clientUrls } from '../../constants/UrlConstants';
import Pagination from '../../components/Pagination';
import NdaModal from './NdaModal';
import Loader from '../../components/Loader';
import TellUsYourStory from '../../components/TellUsYourStory';

import Footer from '../../components/Footer';
import ArtSiteSEO from '../../components/ArtSiteSEO';
import AnimateOnScroll from '../../components/AnimationScroll';
import '../../css/componentscss/Loader.css';
import NoDataFound from '../../components/NoDataFound';
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';
import PageNotFound from '../../components/PageNotFound';
// SubPortfolio Main Page
const SubPortfolio = () => {
  const { state } = useLocation();
  const { slug } = useParams();
  const navigate = useNavigate();
  const subPortfolioRef = useRef(null);
  const lang = useSelector((state) => state.language.selectedLanguage);
  const [displayPortfolio, setDisplayPortfolio] = useState(false);
  const [displayNdaModal, setDisplayNdaModal] = useState(false);
  const [portfolioStaticData, setPortfolioStaticData] = useState([]);
  const [portfolioCategories, setPortfolioCategories] = useState([]);
  const [portfolioAssets, setPortfolioAssets] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loader, setLoader] = useState(true);
  const [portfoliosLoader, setPortfoliosLoader] = useState(true);
  const scrollableRef1 = useRef(null);
  const scrollableRef2 = useRef(null);

  const [selectedCategoryObject, setSelectedCategoryObject] = useState(
    state !== null ? state?.selectedCategoryObject : null,
  );
  const [activePortFolioTitle, setActivePortFolioTitle] = useState(
    state?.selectedCategoryObject?.title,
  );
  const [isPortFoliosExpanded, setIsPortFoiosExpanded] = useState(false);
  useEffect(() => {
    // setBrowserTitle('Portfolio');
    scrollToTop();
    let isSubmitted = Number(localStorage.getItem('is_signed_ptw_nda'));
    let isSubmittedCookie = Number(Cookies.get('is_signed_ptw_nda'));
    if (isSubmitted === 1 || isSubmittedCookie === 1) {
      callPortfolioAPI(getLanguage());
    } else {
      setDisplayNdaModal(true);
    }
  }, [lang]);

  const callPortfolioStaticAPI = async (language) => {
    try {
      let data = await portfolioStaticPageData(language);
      setPortfolioStaticData(data?.data?.portfolio);
      setDisplayNdaModal(false);
      setDisplayPortfolio(true);
    } catch (err) {
      console.log(err);
    }
  };

  const callPortfolioAPI = () => {
    setLoader(true);
    callPortfolioStaticAPI(getLanguage());
    callPortfolioCategories(getLanguage());
    if (state?.selectedCategoryObject?.Id) {
      callPortfolioCategoriesAndData(
        getLanguage(),
        1,
        state?.selectedCategoryObject?.Id,
      );
    }
  };

  const callPortfolioCategories = async (language, pageNo = 1) => {
    let obj = {
      lang: language,
      pageno: pageNo,
      recordsperpage: 10,
    };
    try {
      let data = await getPortfolioCategories(obj);
      setPortfolioCategories(
        data?.data?.portfolios.sort((a, b) =>
          a.portfolio_url.localeCompare(b.portfolio_url),
        ),
      );

      if (!state?.selectedCategoryObject?.Id) {
        let title = slug;

        const catArray = data?.data?.portfolios;
        const catObj = catArray?.find(
          (obj) => obj.portfolio_url?.toLowerCase() === title?.toLowerCase(),
        );
        setSelectedCategoryObject(catObj);
        setActivePortFolioTitle(catObj?.title);
        callPortfolioCategoriesAndData(getLanguage(), 1, catObj?.Id);
      }
    } catch (err) {
      console.log(err);
    } finally {
      // setLoader(false);
    }
  };

  const callPortfolioCategoriesAndData = async (language, pageNo = 1, id) => {
    let obj = {
      lang: language,
      pageno: pageNo,
      recordsperpage: 10,
      type: 'PDF',
      id: id,
    };

    try {
      let data = await getPortfolioCategoriesAndAssets(obj);
      setPortfolioAssets(data?.data?.portfolio?.portfolioPdfFiles);
      setTotalPages(data?.data?.portfolio?.totalPages);
    } catch (err) {
      console.error(err);
      setPortfolioAssets(err);
    } finally {
      setLoader(false);
      setPortfoliosLoader(false);
      setTimeout(
        () => {
          document
            ?.getElementById(id)
            ?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
        },
        userAgent.indexOf('Mac') !== -1 ||
          userAgent.includes('Firefox') ||
          userAgent.includes('Fxios')
          ? 1100
          : 300,
      );
    }
  };
  const redirectToSelectedPortfolio = (data) => {
    setSelectedCategoryObject(data);
    let title = data?.portfolio_url?.toLowerCase();
    // if (title === 'ui/ux') {
    //   title = title.replace('/', '-');
    // }
    let url = navigateTo(`${clientUrls.portfolio}/pdfportfolio/${title}`);
    navigate(url, { state: { selectedCategoryObject: data } });
    setPortfoliosLoader(true);
    callPortfolioCategoriesAndData(getLanguage(), 1, data?.Id);
  };

  const onPaginationChange = (currentPage) => {
    setCurrentPage(currentPage);

    setTimeout(() => {
      navigateToPerticularSection(subPortfolioRef);
    }, 200);
    // setPortfolioAssets([])
    setPortfoliosLoader(true);
    callPortfolioCategoriesAndData(
      getLanguage(),
      currentPage,
      selectedCategoryObject.Id,
    );
  };
  if (portfolioAssets?.status === 404) {
    return <PageNotFound />;
  }
  return (
    <>
      <ArtSiteSEO
        title={portfolioStaticData?.seo?.title}
        description={portfolioStaticData?.seo?.description}
        url={getLanguage() !== 'en' ? `/${getLanguage()}${clientUrls.portfolio}/pdfportfolio/${selectedCategoryObject?.portfolio_url}` : `${clientUrls.portfolio}/pdfportfolio/${selectedCategoryObject?.portfolio_url}`}
      />
      {loader ? (
        <Loader />
      ) : (
        <>
          {displayPortfolio && (
            <>
              <section className="pt-16 lg:pt-24" ref={subPortfolioRef}>
                {/* Desktop category starts here  */}
                <div className="subportfolio-page relative hidden lg:block">
                  <span className="shadow"></span>

                  <div className="flex items-center justify-center mt-6">
                    <AnimateOnScroll animation="fade-up" duration={1000}>
                      <Text
                        className="text-white sm:text-[20px] lg:text-6xl 2xl:text-7xl xs:text-[20px] md:text-[40px] font-extrabold pb-10 md:pb-10 "
                        title={
                          portfolioStaticData?.ourGameArtPortfolios
                            ? portfolioStaticData?.ourGameArtPortfolios
                            : 'Our Game art portfolios'
                        }
                        titleTag="h2"
                      />
                    </AnimateOnScroll>
                    <div className="pb-10 lg:pb-10 lg:w-auto lg:h-auto mt-2">
                      <div>
                        <Image
                          src="/images/arrow-team.png"
                          alt="Image"
                          className="h-auto w-5 lg:w-auto mt-4 lg:mt-12 lg:ml-4 ml-2"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="custom-container1  lg:flex w-[90%] lg:w-[80%] mx-auto ">
                    <SimpleBar
                      style={{ maxHeight: '500px', width: '30%' }}
                      autoHide={false}
                      ref={scrollableRef1}
                    >
                      <div className="sub-portfolio-type grid gap-y-4 lg:gap-y-[4rem]  mr-6">
                        {portfolioCategories.length > 0
                          ? portfolioCategories.map((data, index) => {
                              return (
                                <div
                                  className={
                                    selectedCategoryObject !== null
                                      ? data.Id === selectedCategoryObject?.Id
                                        ? 'active flex  pointer-events-none'
                                        : 'flex  cursor-pointer'
                                      : index === 0
                                        ? 'active flex cursor-pointer'
                                        : 'flex cursor-pointer'
                                  }
                                  onClick={(e) => {
                                    redirectToSelectedPortfolio(data);
                                    // setTimeout(() => {
                                    navigateToPerticularSection(
                                      subPortfolioRef,
                                    );
                                    // }, 200);
                                  }}
                                  key={index}
                                  id={index > 4 ? data?.Id : null}
                                >
                                  <p className="uppercase md:text-[20px] lg:text-[28px] xl:text-[30px] font-medium">
                                    {' '}
                                    {data.title}
                                  </p>
                                  {index == 0 ? (
                                    <p className="ml-2 lg:hidden">+</p>
                                  ) : (
                                    ''
                                  )}
                                </div>
                              );
                            })
                          : null}
                      </div>
                    </SimpleBar>
                    {portfoliosLoader ? (
                      <div className=" sub-portfolio-detail relative lg:flex flex-col gap-y-[2rem] overflow-auto h-full lg:h-[500px] custom-scroll w-[70%]">
                        <Loader />
                      </div>
                    ) : (
                      <SimpleBar
                        style={{ height: '500px', width: '70%' }}
                        ref={scrollableRef2}
                        autoHide={false}
                      >
                        <div className=" sub-portfolio-detail relative lg:flex flex-col items-baseline gap-y-[2rem] lg:h-[500px] ">
                          {portfolioAssets !== null &&
                          portfolioAssets.length > 0 ? (
                            portfolioAssets.map((data, index) => {
                              return (
                                <div
                                  className="flex detail-item items-center ml-9 gap-[3rem]"
                                  key={index}
                                >
                                  <img src={data.icon} />
                                  <a
                                    href={data.pdf}
                                    target="_blank"
                                    className="uppercase hover:text-primary"
                                  >
                                    {data.name}
                                  </a>
                                </div>
                              );
                            })
                          ) : (
                            <div className="place-self-center">
                              <NoDataFound
                                NoDataFound={portfolioStaticData?.noDataFound}
                              />
                            </div>
                          )}
                        </div>
                      </SimpleBar>
                    )}
                  </div>
                  <div className="w-[80%] mx-auto my-20">
                    {portfolioAssets !== null && totalPages > 1 ? (
                      <div className="flex items-center justify-center pagination m-">
                        <Pagination
                          currentPage={currentPage}
                          totalPages={totalPages}
                          onPaginationValueChange={onPaginationChange}
                        />
                      </div>
                    ) : null}
                  </div>
                </div>
                {/* Desktop category ends here  */}

                {/* Mobile category starts here  */}
                <div className="subportfolio-page relative block lg:hidden">
                  <span className="shadow"></span>
                  <div className="flex items-center justify-center mt-6">
                    <AnimateOnScroll animation="fade-up" duration={1000}>
                      <Text
                        className="text-white vs:text-[18px] sm:text-[32px] lg:text-6xl 2xl:text-7xl xs:text-[32px] font-extrabold uppercase"
                        title="Our Game Art"
                        titleTag="h2"
                      />
                    </AnimateOnScroll>
                  </div>
                  <div className="flex justify-center">
                    <AnimateOnScroll animation="fade-up" duration={1000}>
                      <Text
                        className="text-white vs:text-[18px] sm:text-[32px] lg:text-6xl 2xl:text-7xl xs:text-[32px] font-extrabold uppercase"
                        title="Portfolios"
                        titleTag="h2"
                      />
                    </AnimateOnScroll>
                    <div className="pb-10 lg:pb-10 lg:w-auto lg:h-auto mt-2">
                      <div>
                        <Image
                          src="/images/arrow-team.png"
                          alt="Image"
                          className="h-auto w-5 lg:w-auto vs:mt-0 xs:mt-4 lg:mt-12 lg:ml-4 ml-2"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="custom-container1 block lg:flex w-[90%] lg:w-[80%] mx-auto">
                    <div className="sub-portfolio-type mb-6 w-full lg:w-[30%] grid gap-y-4 lg:gap-y-[4rem] overflow-auto h-full lg:h-[500px] custom-scroll mr-6">
                      <div
                        className={`flex cursor-pointer ${isPortFoliosExpanded ? '' : 'active'}`}
                      >
                        <p
                          className="uppercase"
                          onClick={() => {
                            setIsPortFoiosExpanded(!isPortFoliosExpanded);
                          }}
                        >
                          {activePortFolioTitle}{' '}
                          {isPortFoliosExpanded ? '-' : '+'}
                        </p>
                      </div>
                      {portfolioCategories.length > 0 && isPortFoliosExpanded
                        ? portfolioCategories
                            .filter(
                              (port) => port?.title !== activePortFolioTitle,
                            )
                            .map((data, index) => {
                              return (
                                <div
                                  className="flex cursor-pointer"
                                  onClick={(e) => {
                                    setIsPortFoiosExpanded(false);
                                    redirectToSelectedPortfolio(data);
                                  }}
                                  key={index}
                                >
                                  <p
                                    className="uppercase"
                                    onClick={() => {
                                      setActivePortFolioTitle(data?.title);
                                    }}
                                  >
                                    {' '}
                                    {data.title}{' '}
                                  </p>
                                </div>
                              );
                            })
                        : null}
                    </div>
                    {portfoliosLoader ? (
                      <div className=" sub-portfolio-detail relative lg:grid items-baseline gap-y-[2rem] overflow-auto h-full lg:h-[500px] custom-scroll w-full">
                        <div className="flex justify-center items-center h-32">
                          <span className="bar"></span>
                          <span className="bar"></span>
                          <span className="bar"></span>
                        </div>
                      </div>
                    ) : (
                      <>
                        {!isPortFoliosExpanded && (
                          <div className=" sub-portfolio-detail relative lg:grid items-baseline gap-y-[2rem] overflow-auto h-full lg:h-[500px] custom-scroll w-full">
                            {portfolioAssets !== null &&
                            portfolioAssets.length > 0 ? (
                              portfolioAssets.map((data, index) => {
                                return (
                                  <div
                                    className="flex detail-item items-center ml-9 gap-[3rem] mb-4"
                                    key={index}
                                  >
                                    <img src={data.icon} />
                                    <a
                                      href={data.pdf}
                                      target="_blank"
                                      className="uppercase hover:text-primary"
                                    >
                                      {data.name}
                                    </a>
                                  </div>
                                );
                              })
                            ) : (
                              <div className="flex items-center justify-center ">
                                <NoDataFound
                                  NoDataFound={portfolioStaticData?.noDataFound}
                                />
                              </div>
                            )}
                          </div>
                        )}
                      </>
                    )}
                  </div>
                  <div className="w-[80%] mx-auto my-20">
                    {portfolioAssets !== null && totalPages > 1 ? (
                      <div className="flex items-center justify-center pagination m-">
                        <Pagination
                          currentPage={currentPage}
                          totalPages={totalPages}
                          onPaginationValueChange={onPaginationChange}
                        />
                      </div>
                    ) : null}
                  </div>
                </div>
                {/* Mobile category ends here  */}

                <div className="absolute h-[45%] w-[55%] bottom-[-12%] right-[0%] -z-10 hidden lg:block">
                  <img
                    className="w-full h-full"
                    src="/images/tell_us_shadow.png"
                  />
                </div>
                <TellUsYourStory />
              </section>
              <Footer />
            </>
          )}
        </>
      )}

      {displayNdaModal ? (
        <NdaModal displayModal={displayNdaModal} callApi={callPortfolioAPI} />
      ) : null}
    </>
  );
};

export default SubPortfolio;
