// Declaring the routes to redirecting to pages
import { Routes, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import NavigationBar from './components/NavigationBar';
import { clientUrls } from './constants/UrlConstants';
import HomePage from './pages/HomePage';
import ServicesPage from './pages/ServicesPage';
import ProjectsPage from './pages/Projects/ProjectsPage';
import ProjectInner from './pages/Projects/ProjectInner';
import Portfolio from './pages/Portfolio/Portfolio';
import SubPortfolio from './pages/Portfolio/SubPortFolio';
import Contact from './pages/Contact';
import store from './store/store';
import Footer from './components/Footer';
import { useEffect, useState } from 'react';
import NetWorkError from './components/NetWorkError';
import { HelmetProvider } from 'react-helmet-async';
import PageNotFound from './components/PageNotFound';
import { userAgent } from './constants/GeneralConstants';

const App = () => {
  const [isOnline, setIsOnline] = useState(true);

  useEffect(() => {
    const updateOnlineStatus = () => {
      setIsOnline(navigator.onLine);
    };

    window.addEventListener('online', updateOnlineStatus);
    window.addEventListener('offline', updateOnlineStatus);

    return () => {
      window.removeEventListener('online', updateOnlineStatus);
      window.removeEventListener('offline', updateOnlineStatus);
    };
  }, []);
  if (!isOnline) {
    return (
      <>
        <Provider store={store}>
          <NavigationBar />
          <NetWorkError />
          <Footer />
        </Provider>
      </>
    );
  }

  return (
    <div className={userAgent.indexOf('Mac') !== -1 ? '' : ''}>
      <Provider store={store}>
        <HelmetProvider>
          <NavigationBar />
          <Routes>
            <Route exact path={clientUrls.home} element={<HomePage />} />
            <Route
              exact
              path={clientUrls.homeWithLanguage}
              element={<HomePage />}
            />
            <Route
              exact
              path={clientUrls.services}
              element={<ServicesPage />}
            />
            <Route
              exact
              path={clientUrls.servicesWithLanguage}
              element={<ServicesPage />}
            />
            <Route
              exact
              path={clientUrls.projects}
              element={<ProjectsPage />}
            />
            <Route
              exact
              path={clientUrls.projectsWithLanguage}
              element={<ProjectsPage />}
            />
            <Route
              exact
              path={clientUrls.projectDescription}
              element={<ProjectInner />}
            />
            <Route
              exact
              path={clientUrls.projectDescriptionWithLanguage}
              element={<ProjectInner />}
            />
            <Route exact path={clientUrls.portfolio} element={<Portfolio />} />
            <Route
              exact
              path={clientUrls.portfolioWithLanguage}
              element={<Portfolio />}
            />
            <Route
              exact
              path={clientUrls.subPortfolio}
              element={<Portfolio />}
            />
            <Route
              exact
              path={clientUrls.subPortfolioWithLanguage}
              element={<Portfolio />}
            />
            <Route
              exact
              path={clientUrls.morePortfolio}
              element={<SubPortfolio />}
            />
            <Route
              exact
              path={clientUrls.morePortfolioWithLanguage}
              element={<SubPortfolio />}
            />
            <Route exact path={clientUrls.contact} element={<Contact />} />
            <Route
              exact
              path={clientUrls.contactWithLanguage}
              element={<Contact />}
            />
            pageNotFoundWithLang
            <Route
              exact
              path={clientUrls.pageNotFoundWithLang}
              element={<PageNotFound />}
            />
            <Route exact path="*" element={<PageNotFound />} />
          </Routes>
          {/* <TellUsFooterCombained /> */}
        </HelmetProvider>
      </Provider>
    </div>
  );
};

export default App;
