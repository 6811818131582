import { useEffect, useRef, useState } from 'react';
import Text from '../../components/Text';
import Cookies from 'js-cookie';
import {
  getLanguage,
  languageCodes,
  navigateTo,
  scrollToTop,
} from '../../constants/GeneralConstants';
import '../../css/pagescss/Portfolio.css';
import {
  getPortfolioCategories,
  getPortfolioCategoriesAndAssets,
  portfolioStaticPageData,
} from '../../apiservices/ApiService';
import { useSelector } from 'react-redux';
import TellUsYourStory from '../../components/TellUsYourStory';
import { clientUrls } from '../../constants/UrlConstants';
import { useNavigate, useParams } from 'react-router-dom';
import Pagination from '../../components/Pagination';
import Loader from '../../components/Loader';
import ImageModal from './ImageModal';
import NdaModal from './NdaModal';
import Footer from '../../components/Footer';
import ArtSiteSEO from '../../components/ArtSiteSEO';
import PortfolioImage from '../../components/PortFolioImages';
import '../../css/componentscss/Loader.css';
import AnimateOnScroll from '../../components/AnimationScroll';
import NoDataFound from '../../components/NoDataFound';
import PageNotFound from '../../components/PageNotFound';

// Portfolio Main Page
const Portfolio = () => {
  const lang = useSelector((state) => state.language.selectedLanguage);
  const { lang: lang2 } = useParams();
  const navigate = useNavigate();
  const [displayPortfolio, setDisplayPortfolio] = useState(false);
  const [displayNdaModal, setDisplayNdaModal] = useState(false);
  const [portfolioStaticData, setPortfolioStaticData] = useState([]);
  const [portfolioCategories, setPortfolioCategories] = useState([]);
  const [portfolioAssets, setPortfolioAssets] = useState([]);
  const [selectedCategoryObject, setSelectedCategoryObject] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loader, setLoader] = useState(true);
  const [paginationLoader, setPaginationLoader] = useState(false);
  const [imageData, setImageData] = useState(null);
  const [imageIndex, activeImageIndex] = useState(0);
  const [imageModal, setImageModal] = useState(false);
  const imageModalref = useRef(null);
  const portfolioRef = useRef(null);

  useEffect(() => {
    scrollToTop();
    setCurrentPage(1);
    let isSubmitted = Number(localStorage.getItem('is_signed_ptw_nda'));
    let isSubmittedCookie = Number(Cookies.get('is_signed_ptw_nda'));
    if (isSubmitted === 1 || isSubmittedCookie === 1) {
      callPortfolioAPI(getLanguage());
    } else {
      setLoader(false);
      setDisplayNdaModal(true);
    }
    setSelectedCategoryObject(null);
  }, [lang]);

  useEffect(() => {
    if (imageModal) {
      window.addEventListener('keydown', handleKeyDown);
    } else {
      window.removeEventListener('keydown', handleKeyDown);
    }

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [imageModal, imageIndex]);

  const callPortfolioStaticAPI = async (language) => {
    try {
      let data = await portfolioStaticPageData(language);
      setPortfolioStaticData(data?.data?.portfolio);
      // setModalButtonDisabled(false);
      setDisplayNdaModal(false);
      setDisplayPortfolio(true);
    } catch (err) {
      console.log(err);
    }
  };

  const callPortfolioAPI = () => {
    setLoader(true);
    scrollToTop();
    callPortfolioStaticAPI(getLanguage());
    callPortfolioCategories(getLanguage());
  };

  const callPortfolioCategories = async (language, pgNo = 1) => {
    let obj = {
      lang: language,
      pageno: pgNo,
      recordsperpage: 12,
    };
    try {
      let data = await getPortfolioCategories(obj);
      setPortfolioCategories(
        data?.data?.portfolios.sort((a, b) =>
          a.portfolio_url.localeCompare(b.portfolio_url),
        ),
      );
      setPortfolioAssets(data?.data?.portfolioImages);
      setTotalPages(data?.data?.totalPages);
    } catch (err) {
      console.log(err);
    } finally {
      setLoader(false);
      setPaginationLoader(false);
    }
  };

  const callPortfolioCategoriesAndData = async (language, pageNo = 1, id) => {
    let obj = {
      lang: language,
      pageno: pageNo,
      recordsperpage: 12,
      type: 'IMG',
      id: id,
    };
    try {
      let data = await getPortfolioCategoriesAndAssets(obj);
      setPortfolioAssets(data?.data?.portfolio?.portfolioImages);
    } catch (err) {
      console.log(err);
    } finally {
      setLoader(false);
      setPaginationLoader(false);
    }
  };

  const updateSelectedCategoriesAssets = (obj) => {
    setSelectedCategoryObject(obj);
    if (obj !== null) {
      callPortfolioCategoriesAndData(getLanguage(), 1, obj.Id);
    } else {
      callPortfolioCategories(getLanguage());
    }
  };

  const onPaginationChange = (currentPage) => {
    if (portfolioRef.current != null) {
      const headerOffset = 100;

      const elementPosition = portfolioRef.current.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.scrollY - headerOffset;
      const outerTimeOut = setTimeout(() => {
        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth',
        });
        const innerTimeout = setTimeout(() => {
          window.scrollTo({
            top: offsetPosition,
            behavior: 'smooth',
          });
          clearTimeout(innerTimeout);
        }, 400);
        clearTimeout(outerTimeOut);
      }, 0);
    }
    setPaginationLoader(true);

    setCurrentPage(currentPage);
    callPortfolioCategories(getLanguage(), currentPage);
  };

  const displayImageModal = (index) => {
    if (imageModalref.current !== null) {
      setImageModal(true);
      setImageData(portfolioAssets[index]);
      activeImageIndex(index);
      document.body.classList.add('no-scroll');
      imageModalref.current.classList.toggle('show-modal');
    }
  };

  const closeImageModal = () => {
    if (imageModalref.current !== null) {
      setImageModal(false);
      document.body.classList.remove('no-scroll');
      imageModalref.current.classList.toggle('show-modal');
    }
  };

  const handleKeyDown = (event) => {
    if (imageModal) {
      if (event.key === 'ArrowRight') {
        changeTheImageInPopUp(imageIndex, 'R');
      } else if (event.key === 'ArrowLeft') {
        changeTheImageInPopUp(imageIndex, 'L');
      } else if (event.key === 'Escape') {
        closeImageModal();
      }
    }
  };

  const changeTheImageInPopUp = (index, direction) => {
    let newIndex = index;
    const lastIndex = portfolioAssets.length - 1;

    if (direction === 'R') {
      newIndex = index < lastIndex ? index + 1 : index;
    } else if (direction === 'L') {
      newIndex = index > 0 ? index - 1 : index;
    }

    setImageData(portfolioAssets[newIndex]);
    activeImageIndex(newIndex);
  };

  const navigateToPerticularPortfolio = (obj) => {
    let title = obj.portfolio_url.toLowerCase();
    // if (title === 'ui/ux') {
    //   title = title.replace('/', '-');
    // }
    navigate(navigateTo(`${clientUrls.portfolio}/pdfportfolio/${title}`), {
      state: { selectedCategoryObject },
    });
  };

  if (loader) {
    return <Loader />;
  }
  if (lang2 && !languageCodes.includes(lang2)) {
    return <PageNotFound />;
  }
  return (
    <>
      <ArtSiteSEO
        title={portfolioStaticData?.seo?.title}
        description={portfolioStaticData?.seo?.description}
        url={
          getLanguage() !== 'en'
            ? `/${getLanguage()}${clientUrls.portfolio}`
            : `${clientUrls.portfolio}`
        }
      />
      {displayPortfolio && (
        <section id="portfolio-page" className="pt-16 lg:pt-24">
          <div className="relative">
            <div
              className={`portfolio-page relative ${imageModal ? 'blur-background' : ''}`}
            >
              <span className="shadow"></span>

              {/* portfolio heading section starts here  */}
              <div className="portfolio-header my-8 lg:my-[6rem]">
                <h2 className="font-extrabold text-2xl xs:text-4xl md:text-6xl lg:text-[60px] xl:text-[80px]  2xl:text-[98px] 3xl:text-[112px] uppercase p-1 text-center leading-7 xs:leading-9 lg:leading-[1]">
                  <AnimateOnScroll animation="fade-up" duration={1000}>
                    <Text
                      className="block"
                      title={portfolioStaticData?.art}
                      titleTag="span"
                    />
                  </AnimateOnScroll>
                  <AnimateOnScroll animation="fade-up" duration={1000}>
                    <Text
                      className="block"
                      title={`${portfolioStaticData?.that} ${' '} ${portfolioStaticData?.speaks}`}
                      titleTag="span"
                    />
                  </AnimateOnScroll>
                  <AnimateOnScroll animation="fade-up" duration={1000}>
                    <Text
                      className="block ml-12 xs:ml-20 md:ml-36 lg:-ml-2"
                      title={`${portfolioStaticData?.for} ${' '} ${portfolioStaticData?.itself}`}
                      titleTag="span"
                    />
                  </AnimateOnScroll>
                </h2>
              </div>
              {/* portfolio heading section ends here  */}

              <div className="portfolio-middle-border my-8 lg:my-[6rem]"></div>

              {/* web menu section starts here  */}
              <div className=" web-menu my-[4rem]" ref={portfolioRef}>
                <div className="my-[3rem]">
                  <AnimateOnScroll animation="fade-up" duration={1000}>
                    <p className="py-4 uppercase text-[16px] block lg:hidden text-[#ff2e29] font-medium">
                      {portfolioStaticData?.ourGameArtPortfolios}
                    </p>
                  </AnimateOnScroll>
                  <nav className=" portfolio-link pl-0 lg:pl-7 grid grid-cols-2 md:grid-cols-3 lg:flex flex-wrap gap-y-[1rem] gap-x-[2rem] py-4">
                    <div className="md:col-span-5 col-span-2">
                      <div
                        id={
                          selectedCategoryObject === null
                            ? 'active'
                            : selectedCategoryObject?.Id
                              ? ''
                              : 'active'
                        }
                        className="text-[16px] lg:text-[20px] text-white hover:underline focus:outline-none cursor-pointer"
                        onClick={(e) => {
                          setPaginationLoader(true);
                          updateSelectedCategoriesAssets(null);
                        }}
                      >
                        {portfolioStaticData?.all}
                      </div>
                    </div>
                    {portfolioCategories.map((category, index) => (
                      <div
                        id={
                          selectedCategoryObject !== null
                            ? category.Id === selectedCategoryObject.Id
                              ? 'active'
                              : ''
                            : ''
                        }
                        className="text-[16px] lg:text-[20px] text-white hover:underline focus:outline-none cursor-pointer"
                        onClick={(e) => {
                          setPaginationLoader(true);
                          updateSelectedCategoriesAssets(category);
                        }}
                        key={index}
                      >
                        {category.title}
                      </div>
                    ))}
                  </nav>
                </div>
                {paginationLoader ? (
                  <div className="flex items-center justify-center h-[160vh]">
                    <span className="bar"></span>
                    <span className="bar"></span>
                    <span className="bar"></span>
                  </div>
                ) : (
                  <>
                    {portfolioAssets.length > 0 ? (
                      <div
                        className={
                          selectedCategoryObject?.portfolio_url ===
                            'characters' || selectedCategoryObject === null
                            ? 'grid grid-cols-1 lg:grid-cols-3 gap-[1rem] lg:gap-[3rem] px-0 lg:px-4 min-h-[70vh]'
                            : 'grid grid-cols-1 lg:grid-cols-2 gap-[1rem] lg:gap-[3rem] px-0 lg:px-4 min-h-[70vh]'
                        }
                      >
                        {portfolioAssets.map((image, index) => (
                          <div
                            key={index}
                            className="p-2 relative cursor-pointer"
                            onClick={(e) => displayImageModal(index)}
                          >
                            <AnimateOnScroll animation="zoom-in">
                              <PortfolioImage
                                uniqueKey={image.image}
                                lowQualityImage={
                                  image.imageLowQuality
                                    ? image.imageLowQuality
                                    : image.image
                                }
                                highQualityImage={image?.image}
                                indexValue={index}
                              />
                            </AnimateOnScroll>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <div className="text-center justify-center">
                        <NoDataFound
                          NoDataFound={portfolioStaticData?.noDataFound}
                        />
                      </div>
                    )}
                  </>
                )}
              </div>
              {/* web menu section ends here  */}

              {/* pdf open section starts here  */}
              {selectedCategoryObject !== null ? (
                <div className="flex items-center justify-center relative z-[999]">
                  <div className="text-center mt-3 mb-24 ">
                    <div
                      onClick={(e) =>
                        navigateToPerticularPortfolio(selectedCategoryObject)
                      }
                      className="pt-2 mt-4 inline-flex items-center hover-bg vs:mx-2 xs:mx-0"
                    >
                      <button
                        className="border pr-[3rem] pl-[2rem] overflow-hidden relative rounded-[50px] uppercase h-11"
                        name=""
                      >
                        {/* Get In Touch */}
                        <span className="vs:text-[10px] xs:text-[12px] lg:text-[16px]">
                          {portfolioStaticData?.seeMore}{' '}
                          {selectedCategoryObject?.title}{' '}
                          {portfolioStaticData?.portfolios}
                        </span>
                      </button>
                      <span className="arrowcircle flex bg-black justify-center items-center border relative overflow-hidden ml-[-2.5rem] w-16 h-11 rounded-full cursor-pointer">
                        <svg
                          width="16"
                          height="11"
                          viewBox="0 0 16 11"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M0.171875 5.27543L0.171875 5.77543L0.671875 5.77543L13.4612 5.77543L9.89449 9.34217L9.54094 9.69573L10.248 10.4028L10.6016 10.0493L15.0219 5.62895L15.3755 5.2754L15.0219 4.92184L10.6016 0.501512L10.248 0.147959L9.54094 0.855066L9.89449 1.20862L13.4613 4.77543L1.17188 4.77543L1.17188 2.1127L1.17188 1.6127L0.171875 1.6127L0.171875 2.1127L0.171875 5.27543Z"
                            fill="white"
                          ></path>
                        </svg>
                      </span>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="flex items-center justify-center pagination">
                  {/* pagination starts here  */}
                  {!paginationLoader ? (
                    <Pagination
                      currentPage={currentPage}
                      totalPages={totalPages}
                      onPaginationValueChange={onPaginationChange}
                    />
                  ) : (
                    ''
                  )}
                  {/* pagination ends here  */}
                </div>
              )}
              {/* pdf open section ends here  */}

              <div className="border-left"></div>
              <div className="border-right"></div>
            </div>

            <ImageModal
              image={imageData}
              modalRef={imageModalref}
              arr={portfolioAssets}
              imageIndex={imageIndex}
              onCloseModal={closeImageModal}
              onImageChange={changeTheImageInPopUp}
              lastIndex={portfolioAssets ? portfolioAssets?.length - 1 : 0}
            />
            <div className="absolute h-[45%] w-[55%] bottom-[-12%] right-[0%] -z-10 hidden lg:block">
              <img className="w-full h-full" src="/images/tell_us_shadow.png" />
            </div>
            {displayPortfolio ? (
              <div className="my-9">
                <TellUsYourStory />
              </div>
            ) : null}
          </div>
        </section>
      )}
      {displayNdaModal ? (
        <NdaModal
          displayModal={displayNdaModal}
          callApi={callPortfolioAPI}
          className="hello"
        />
      ) : null}
      {displayPortfolio && !paginationLoader ? <Footer /> : null}
    </>
  );
};

export default Portfolio;
