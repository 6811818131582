import Button from '../../components/Button';
const ImageModal = ({
  image,
  onCloseModal,
  modalRef,
  arr,
  imageIndex,
  onImageChange,
  lastIndex,
}) => {
  return (
    <div className="image-modal bg-[#000000bf] z-[999]" ref={modalRef}>
      <div className="modal-content">
        <div></div>
        <div className="relative flex justify-center items-center w-[100%] lg:w-[75%] mx-auto px-[1.5rem] py-[1rem]">
          <div className="carousel">
            <div className="carousel-item active">
              <img
                src={image?.image}
                alt="Ptw Art"
                className=" w-full h-auto"
              />
            </div>
          </div>
          <span
            className="close-button bg-primary rounded-full w-[30px] h-[30px] lg:w-[40px] lg:h-[40px] text-[30px] leading-[2rem] lg:leading-[2.5rem] text-center cursor-pointer mr-4 absolute right-0 top-0"
            onClick={(e) => onCloseModal()}
          >
            ×
          </span>
          <Button
            className={`p-2 rounded-full border z-10 absolute top-1/2 left-0 transform -translate-y-1/2 ml-6 lg:ml-12 ${imageIndex === 0 ? 'bg-gray-500' : 'bg-[#000000c7] hover:bg-primary'}`}
            type="button"
            onClick={(e) => onImageChange(imageIndex, 'L')}
            disabled={imageIndex === 0 ? true : false}
          >
            <svg
              className="h-5 w-5 lg:h-8 lg:w-8 p-1"
              viewBox="0 0 16 11"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                strokeWidth=" 0px"
                fill="#fefbf7"
                className="cls-1"
                d="M15.8,5.7v-.5H1.9l3.8-3.8.3-.3-.6-.6-.3.3L.4,5.3l-.3.3.3.3,4.6,4.6.3.3.6-.6-.3-.3-3.8-3.8h13.1v3.3h.9v-3.7Z"
              />
            </svg>
          </Button>
          <Button
            className={`p-2 rounded-full border z-10 absolute top-1/2 right-0 transform -translate-y-1/2 mr-6 lg:mr-12  ${imageIndex === lastIndex ? 'bg-gray-500' : 'bg-[#000000c7] hover:bg-primary'}`}
            type="button"
            onClick={(e) => onImageChange(imageIndex, 'R')}
            disabled={imageIndex === lastIndex ? true : false}
          >
            <svg
              className="h-5 w-5 lg:h-8 lg:w-8 p-1"
              viewBox="0 0 16 11"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0.171875 5.27543L0.171875 5.77543L0.671875 5.77543L13.4612 5.77543L9.89449 9.34217L9.54094 9.69573L10.248 10.4028L10.6016 10.0493L15.0219 5.62895L15.3755 5.2754L15.0219 4.92184L10.6016 0.501512L10.248 0.147959L9.54094 0.855066L9.89449 1.20862L13.4613 4.77543L1.17188 4.77543L1.17188 2.1127L1.17188 1.6127L0.171875 1.6127L0.171875 2.1127L0.171875 5.27543Z"
                fill="white"
              ></path>
            </svg>
          </Button>
          <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 flex space-x-2 my-4">
            {arr?.length > 0
              ? arr.map((data, index) => {
                  return (
                    <button
                      key={index}
                      className={
                        imageIndex === index
                          ? 'w-3 h-3 bg-primary border border-primary rounded-full focus:outline-none'
                          : 'w-3 h-3 bg-white border border-primary rounded-full focus:outline-none'
                      }
                      data-index={index}
                      onClick={(e) => onImageChange(index, null)}
                    ></button>
                  );
                })
              : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImageModal;
