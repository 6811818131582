//List of apis we are calling
export const apis = {
  navigationBarAndFooter: '/header',
  homePageData: '/home',
  contactData: '/contact',
  postContactData: 'contact',
  projectsData: '/project',
  projectsListStaticData: '/projects',
  ndaPopUPContent: '/nda',
  ndaSubmission: 'confidential',
  portfolioStaticData: '/portfolios',
  portfolioCategories: 'allportfolio',
  portfolioCategoriesAndData: 'artportfolio',
  featuredProject: '/featuredproject',
  projectFilters: '/projectfilters',
  projectsListingData: 'project',
  services: '/service',
  pageNotFound: '/nopage',
};
