// Component For Text With Multiple Elements

const Text = ({ title, className, titleTag: TitleTag = 'h1' }) => {
  return (
    <>
      <TitleTag className={className}>{title}</TitleTag>
    </>
  );
};

export default Text;
