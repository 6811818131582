import { useState } from 'react';

const PortfolioImage = ({
  lowQualityImage,
  highQualityImage,
  indexValue,
  uniqueKey,
}) => {
  const [src, setSrc] = useState(lowQualityImage);
  const [isLoaded, setIsLoaded] = useState(false);

  const handleLoadImage = (hqImage) => {
    setSrc(hqImage);
    setIsLoaded(true);
  };
  return (
    <img
      key={uniqueKey}
      src={src}
      alt={`Artwork ${indexValue + 1}`}
      className="w-full h-full object-cover relative"
      onLoad={(e) => handleLoadImage(highQualityImage)}
      style={{ filter: isLoaded ? 'none' : 'blur(7px)' }}
    />
  );
};

export default PortfolioImage;
