// this Component is for Contact Page

import { useEffect, useState } from 'react';
import { getLanguage, languageCodes, setBrowserTitle } from '../constants/GeneralConstants';
import Text from '../components/Text';
import ContactForm from '../components/ContactForm';
import '../css/pagescss/utilities.css';
import '../css/pagescss/Contact.css';
import { getContactData } from '../apiservices/ApiService';
import { Link, useParams } from 'react-router-dom';
import Loader from '../components/Loader';
import PageNotFound from '../components/PageNotFound';
import Footer from '../components/Footer';
import ArtSiteSEO from '../components/ArtSiteSEO';
import PILImg from '../components/PILImg';
import AnimateOnScroll from '../components/AnimationScroll';
import { clientUrls } from '../constants/UrlConstants';

const Contact = () => {
  const [data, setData] = useState();
  const [loader, setLoader] = useState(true);
  const [displayCareersButton, setDisplayCareersButton] = useState(false);
  const { lang } = useParams();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      const response = await getContactData(lang ? lang : 'en');
      setData(response);

      setLoader(false);
    };
    fetchData();
  }, [lang]);
  if (lang && !languageCodes.includes(lang)) {
    return <PageNotFound />;
  }
  return (
    <>
      <ArtSiteSEO
        title={data?.contact?.seo?.title}
        description={data?.contact?.seo?.description}
        url={getLanguage() !== 'en' ? `/${getLanguage()}${clientUrls.contact}` : `${clientUrls.contact}`}
      />
      {loader ? (
        <Loader />
      ) : (
        <>
          <section id="contact-page" className="pt-16 lg:pt-24">
            {data?.contact ? (
              <div className="contact-us">
                {/* shadow section starts here  */}
                <span className="bg-[#ffffffa8] rounded-full block blur-[30px] lg:blur-[40px] h-full left-[-30%] opacity-15 absolute top-32 lg:top-[25%] transform rotate-[5deg] w-full z-[-1]"></span>
                {/* shadow section ends here  */}

                {/* map image section starts here  */}
                <div className="absolute right-0 text-right top-[6rem] lg:top-[13%] z-[-11]">
                  <PILImg
                    alt=""
                    src={data?.contact?.contactHeadingSection?.bgImage}
                    className="opacity-80 lg:opacity-40 w-[80%] lg:w-[65%] float-right pt-8"
                    lowQaulitySrc={
                      data?.contact?.contactHeadingSection?.bgImageLowQuality
                        ? data?.contact?.contactHeadingSection
                            ?.bgImageLowQuality
                        : data?.contact?.contactHeadingSection?.bgImage
                    }
                  />
                  <span className="map-gradient absolute top-0 right-0 w-full h-full opacity-60 blur-[20px]"></span>
                </div>
                {/* map image section ends here  */}

                <div className="text-center px-5 py-12">
                  <AnimateOnScroll animation="fade-up" duration={1000}>
                    <Text
                      className={
                        'text-[10px] xs:text-xs sm:text-sm md:text-base lg:text-[24px] font-medium tracking-[3px]'
                      }
                      title={
                        data?.contact?.contactHeadingSection?.joinUsOnOurQuestTo
                      }
                      titleTag="h3"
                    />
                  </AnimateOnScroll>
                  <AnimateOnScroll animation="fade-up" duration={1000}>
                    <h2 className="font-extrabold lg:text-[60px] md:text-6xl 2xl:text-[98px] xl:text-[80px] vs:text-[30px] leading-[1] xs:text-4xl uppercase p-1">
                      <Text
                        className="block"
                        title={`${data?.contact?.contactHeadingSection?.create} ${data?.contact?.contactHeadingSection?.game}`}
                        titleTag="span"
                      />
                      <Text
                        className="block vs:ml-[6rem] xs:ml-[7rem] md:ml-44 lg:ml-56 xl:ml-72 2xl:ml-80"
                        title={data?.contact?.contactHeadingSection?.changing}
                        titleTag="span"
                      />
                      <Text
                        className="block"
                        title={
                          data?.contact?.contactHeadingSection?.experiences
                        }
                        titleTag="span"
                      />
                    </h2>
                  </AnimateOnScroll>
                </div>

                {/* contact form section starts here  */}
                <ContactForm data={data} />

                {/* desktop contact footer section starts here   */}
                <section
                  className="hidden lg:block contact-bg lg:h-[50rem] 2xl:h-[63rem]"
                  // style={{justifyContent: 'center'}}
                  style={{
                    background: `url(${data?.contact?.contactBottomSection?.bottomBgImage})`,
                  }}
                  id="desktop-info"
                  onMouseEnter={(e) => {
                    setDisplayCareersButton(true);
                  }}
                  onMouseLeave={(e) => {
                    setDisplayCareersButton(false);
                  }}
                >
                  <div className="my-9 relative flex justify-center items-center">
                    <div className="w-full 2xl:pt-[16rem] lg:pt-[13rem]">
                      <AnimateOnScroll animation="fade-up" duration={1000}>
                        <p className="text-center font-medium text-[24px] relative uppercase">
                          {
                            data?.contact?.contactBottomSection
                              ?.calling_all_artists
                          }
                        </p>
                      </AnimateOnScroll>
                      <div className="relative">
                        <AnimateOnScroll animation="fade-up" duration={1000}>
                          <h2 className="text-center font-extrabold lg:text-[60px] md:text-6xl 2xl:text-[98px] xl:text-[80px] text-4xl leading-[6rem] uppercase p-1">
                            {data?.contact?.contactBottomSection?.join}
                          </h2>
                        </AnimateOnScroll>
                        <AnimateOnScroll animation="fade-up" duration={1000}>
                          <h2 className="text-center font-extrabold lg:text-[60px] md:text-6xl 2xl:text-[98px] xl:text-[80px] text-4xl leading-[6rem] uppercase p-1">
                            {data?.contact?.contactBottomSection?.our}{' '}
                            {data?.contact?.contactBottomSection?.global}
                          </h2>
                        </AnimateOnScroll>
                        <AnimateOnScroll animation="fade-up" duration={1000}>
                          <h2 className="text-center font-extrabold lg:text-[60px] md:text-6xl 2xl:text-[98px] xl:text-[80px] text-4xl leading-[6rem] uppercase p-1">
                            {data?.contact?.contactBottomSection?.art}{' '}
                            {data?.contact?.contactBottomSection?.team}
                          </h2>
                        </AnimateOnScroll>
                        {displayCareersButton ? (
                          <Link
                            to={
                              data?.contact?.contactBottomSection
                                ?.visitPTWCareersForMoreButtonLink
                            }
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <div className="absolute rounded-full bg-orange w-40 h-40  flex items-center justify-center cursor-pointer top-[50%] left-[50%] -translate-y-2/4 -translate-x-2/4	">
                              <p className="text-[18px] font-semibold uppercase text-center rounded-[50%] z-10">
                                {
                                  data?.contact?.contactBottomSection
                                    ?.visitPTWCareersForMoreButton
                                }
                              </p>
                            </div>
                          </Link>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </section>
                {/* desktop contact footer section end here   */}

                {/* mobile contact footer section starts here   */}
                <section
                  className="block lg:hidden contact-bg-mobile h-[25rem] xs:h-[40rem] md:h-[52rem] bg-contain1"
                  // style={{
                  //   background: `url(${data?.contact?.contactBottomSection?.bottomBgImage})`,
                  // }}
                  style={{
                    background: `url(/images/contact-us-mobile.png)`,
                  }}
                  id="mobile-info"
                >
                  <div className="my-9 relative flex justify-center items-center">
                    <div className="w-full vs:pt-[8rem] xs:pt-[15rem] md:pt-[22rem]">
                      <AnimateOnScroll animation="fade-up" duration={1000}>
                        <p className="text-center font-medium text-[15px] relative uppercase">
                          {
                            data?.contact?.contactBottomSection
                              ?.calling_all_artists
                          }
                        </p>
                      </AnimateOnScroll>
                      <div className="relative">
                        <AnimateOnScroll animation="fade-up" duration={1000}>
                          <h2 className="text-center font-extrabold vs:text-[2.5rem] md:text-[4rem] leading-[2.5rem] md:leading-[3.5rem] uppercase">
                            {data?.contact?.contactBottomSection?.join}
                          </h2>
                        </AnimateOnScroll>
                        <AnimateOnScroll animation="fade-up" duration={1000}>
                          <h2 className="text-center font-extrabold vs:text-[2.5rem] md:text-[4rem] leading-[2.5rem] md:leading-[3.5rem] uppercase">
                            {data?.contact?.contactBottomSection?.our}{' '}
                            {data?.contact?.contactBottomSection?.global}
                          </h2>
                        </AnimateOnScroll>
                        <AnimateOnScroll animation="fade-up" duration={1000}>
                          <h2 className="text-center font-extrabold vs:text-[2.5rem] md:text-[4rem] leading-[2.5rem] md:leading-[3.5rem] uppercase">
                            {data?.contact?.contactBottomSection?.art}{' '}
                            {data?.contact?.contactBottomSection?.team}
                          </h2>
                        </AnimateOnScroll>
                        <Link
                          to={
                            data?.contact?.contactBottomSection
                              ?.visitPTWCareersForMoreButtonLink
                          }
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <div className="absolute  bg-orange rounded-full w-20 h-20 md:w-28 md:h-28 flex items-center justify-center cursor-pointer top-[50%] left-[50%]  -translate-y-[50%] -translate-x-[50%]">
                            <p className="vs:text-[10px] md:text-[14px] font-semibold uppercase text-center rounded-[2rem] z-10">
                              {
                                data?.contact?.contactBottomSection
                                  ?.visitPTWCareersForMoreButton
                              }
                            </p>
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div>
                </section>
                {/* mobile contact footer section starts here   */}
              </div>
            ) : (
              ''
            )}
          </section>
          <Footer />
        </>
      )}{' '}
    </>
  );
};

export default Contact;
