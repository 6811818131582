import React from 'react';

const NoDataFound = ({ NoDataFound }) => {
  return (
    <div className="flex justify-center items-center">
      <p className="text-primary text-2xl font-semibold"> {NoDataFound}</p>
    </div>
  );
};

export default NoDataFound;
