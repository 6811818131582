// Axios Instance
import axios from 'axios';

const ApiInstance = axios.create();

ApiInstance.interceptors.response.use(
  (res) => res,
  (error) => {
    const mainRequest = error.config;
    if (error.message === 'Network Error' && !mainRequest._retry) {
      mainRequest._retry = true;
      return ApiInstance(mainRequest);
    }
    return Promise.reject(error);
  },
);

export default ApiInstance;
