// this Component is for Contact Form

import { useEffect, useRef, useState } from 'react';
import { postContactFormData } from '../apiservices/ApiService';
import {
  countries,
  getLanguage,
  userAgent,
} from '../constants/GeneralConstants';
import { Link } from 'react-router-dom';
import '../css/pagescss/Contact.css';
const ContactForm = ({ data }) => {
  const formObj = {
    username: '',
    email: '',
    number: '',
    location: '',
    areaOfInterest: '',
    hearAboutUs: '',
    addAMessage: '',
    terms: '',
  };
  const sortedCountries = countries.sort((a, b) => {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  });
  const [formData, setFormData] = useState(formObj);
  const [errors, setErrors] = useState({});
  const [isSending, setIsSending] = useState(false);
  const [countryList, setCountryList] = useState(sortedCountries);
  const [initialHearAboutUsList, setInitialHearAboutUsList] = useState(
    data?.contact?.contactForm?.howDidYouHearAboutUsSection
      ?.howDidYouHearAboutUsValues,
  );
  const [hearAboutList, setHearAboutList] = useState(
    data?.contact?.contactForm?.howDidYouHearAboutUsSection?.howDidYouHearAboutUsValues.sort(),
  );
  const [showLocationDropdown, setShowLocationDropdon] = useState(false);
  const [showHearAboutUsDropdown, setShowHearAboutUsDropdown] = useState(false);
  const [showCheckTick, setShowcheckTick] = useState(false);
  const [isSubmitClicked, setIsSubmitClicked] = useState(false);
  const locationRef = useRef();
  const hearAboutUsRef = useRef();
  const hearAboutUsInputRef = useRef();
  const locationInputRef = useRef();
  const nameRef = useRef(null);
  const mailRef = useRef(null);
  const numberRef = useRef(null);
  const messageRef = useRef(null);
  const locationMainRef = useRef(null);
  const hearAboutUsMainRef = useState(null);
  const termsRef = useRef(null);

  const handleChange = (e) => {
    const { value, name } = e.target;
    const nmbrRegex = /^[0-9+\-()]*$/;
    if (name === 'number') {
      if (nmbrRegex.test(value)) {
        setFormData({
          ...formData,
          [name]: value,
        });
      }
    } else if (name === 'addAMessage') {
      if (value.includes('<') || value.includes('>')) {
        return;
      } else {
        setFormData({
          ...formData,
          [name]: value,
        });
      }
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  //on blur validation
  const handleBlur = (e) => {
    const { name, value } = e.target;
    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      const validationErrors = validateForm({ ...formData, [name]: value });
      if (name in validationErrors) {
        newErrors[name] = validationErrors[name];
      } else {
        delete newErrors[name];
      }
      return newErrors;
    });
  };
  // seperate condition for checkbox
  const handleTermsChange = (e) => {
    const { checked, name } = e.target;

    setFormData({
      ...formData,
      [name]: checked,
    });
    setShowcheckTick(checked);

    if (!checked) {
      setErrors({
        ...errors,
        terms: 'Please agree for terms and conditions',
      });
    } else {
      setErrors({
        ...errors,
        terms: '',
      });
    }
  };

  const validateEmail = (email) => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    return emailRegex.test(email);
  };
  const validateNumber = (number) => {
    const numberRegex = /^(\+|00|0)?[0-9][0-9 \-\(\)\.]{6,16}$/;

    return numberRegex.test(number);
  };
  const validateName = (name) => {
    const nameRegex = /^[\p{L}\p{M}\s'-]+$/u;
    return nameRegex.test(name);
  };
  const validateForm = (data) => {
    let formErrors = {};
    if (!data.username.trim()) {
      formErrors.username = 'Please enter a username';
    } else if (data?.username?.replace(/\s+/g, '').length < 3) {
      formErrors.username = 'Name must be minimum 3 letters';
    } else if (!validateName(data.username.trim())) {
      formErrors.username2 =
        'Name should not contain specail characters and numbers';
    }
    if (data.number !== '' && !validateNumber(formData.number)) {
      formErrors.number = 'Please enter valid mobile number';
    }
    if (!data.email.trim()) {
      formErrors.email = 'Please enter a email';
    } else if (!validateEmail(data.email)) {
      formErrors.email = 'Please enter a valid email';
    }
    if (!data.location) {
      formErrors.location = 'Please select a location';
    }
    if (!data.hearAboutUs) {
      formErrors.hearAboutUs = 'Please select one option';
    }
    if (!data.addAMessage.trim()) {
      formErrors.addAMessage = 'Please add a message';
    } else if (data.addAMessage.trim().length < 20) {
      formErrors.addAMessage = 'Message must be 20 charecters lenth';
    }
    if (!data.terms) {
      formErrors.terms = 'Please agree for terms and conditions';
    }

    return formErrors;
  };
  const handleLocationSearch = (e) => {
    const filtered = countries.filter((c) =>
      c?.name?.toLowerCase().includes(e.target.value.toLowerCase()),
    );
    setCountryList([...filtered]);
  };
  const handleHearAboutSearch = (e) => {
    let { value } = e.target;
    let filtered = [];
    if (value.toLowerCase() === 'x') {
      filtered = initialHearAboutUsList?.filter((i) => i === '𝕏');
    } else {
      filtered = initialHearAboutUsList?.filter((i) =>
        i?.toLowerCase().includes(value.toLowerCase()),
      );
    }
    setHearAboutList(filtered);
  };
  const getFirstErrorFieldRef = (errors) => {
    if (errors.username || errors.username2) {
      return nameRef;
    }
    if (errors.email) {
      return mailRef;
    }
    if (errors.number) {
      return numberRef;
    }
    if (errors.location) {
      return locationMainRef;
    }
    if (errors.hearAboutUs) {
      return hearAboutUsMainRef;
    }
    if (errors.addAMessage) {
      return messageRef;
    }
    if (errors.terms) {
      return termsRef;
    }
    return null;
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    const validationErrors = validateForm(formData);

    if (Object.keys(validationErrors).length === 0) {
      setIsSending(true);
      setFormData(formObj);
      setShowcheckTick(false);
      setIsSubmitClicked(false);

      postContactFormData({
        conditions: formData.terms,
        email: formData.email,
        hear: formData.hearAboutUs,
        location: formData.location,
        message: formData.addAMessage,
        name: formData.username,
        areaofinterest: formData.areaOfInterest || '',
        phone: formData.number || '',
      }).then((res) => {
        // if (res.code === 'success') {
        setTimeout(() => {
          setIsSending(false);
        }, 500);
        // }
      });
    } else {
      setErrors(validationErrors);
      setIsSubmitClicked(true);
      const errorFieldRef = getFirstErrorFieldRef(validationErrors);
      if (errorFieldRef) {
        errorFieldRef.current.focus();
        errorFieldRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      }
    }
  };
  const handleCountryClick = (e) => {
    setFormData((data) => ({ ...data, location: e.target.innerText }));
    setErrors({ ...errors, location: '' });
    setShowLocationDropdon(false);
  };
  const handleHearAboutUsClick = (e) => {
    setFormData((data) => ({ ...data, hearAboutUs: e.target.innerText }));
    setErrors({ ...errors, hearAboutUs: '' });
    setShowHearAboutUsDropdown(false);
  };
  const handleOutSideClick = (e) => {
    if (
      locationRef.current &&
      !locationRef.current.contains(e.target) &&
      e.target.id !== 'location'
    ) {
      setShowLocationDropdon(false);
    }

    if (
      hearAboutUsRef.current &&
      !hearAboutUsRef.current.contains(e.target) &&
      e.target.id !== 'hearAboutUs'
    ) {
      setShowHearAboutUsDropdown(false);
    }
  };
  useEffect(() => {
    document.addEventListener('click', handleOutSideClick, false);
    return () => {
      document.removeEventListener('click', handleOutSideClick, false);
    };
  }, [locationRef, hearAboutUsRef]);

  const handleLength = (e) => {
    let iValue = e.target.value;
    if (iValue.length > 15) {
      iValue = iValue.slice(0, 15);
      e.target.value = iValue;
    }
  };
  return (
    <>
      {data?.contact && (
        <div className="flex align-center justify-center mt-4 lg:mt-24 mb-20 lg:mr-5 lg:ml-5">
          {/* <div className='form-bg lg:py-12 lg:px-28 px-5 py-5 bg-[#0C0C0C] border-[#ffffff4d] border'> */}
          <div className="form-bg lg:py-12 lg:px-28 px-5 py-5 radial-gradient-bg border-[#ffffff4d] border mx-6 lg:mx-0 shadow-2xl">
            <form onSubmit={handleSubmit} className="form-border">
              <div className="form grid grid-cols-1 lg:grid-cols-2 vs:gap-7 md:gap-16">
                <div className={getLanguage() === 'en' ? 'uppercase' : ''}>
                  <label className="cursor-pointer">
                    {data?.contact?.contactForm?.name}{' '}
                    <span className="text-primary">*</span>
                    <input
                      ref={nameRef}
                      type="text"
                      name="username"
                      className="w-full text-white p-1 appearance-none bg-transparent rounded-none border-b border-white focus:outline-none focus:border-primary"
                      onChange={(e) => {
                        handleChange(e);
                        if (isSubmitClicked) handleBlur(e);
                      }}
                      value={formData.username}
                      maxLength={30}
                      onBlur={handleBlur}
                      tabIndex={1}
                    ></input>
                  </label>
                  <div className="lg:w-[25rem] h-1 py-3">
                    {(errors.username || errors.username2) && (
                      <p className="text-primary text-xs  uppercase ">
                        {errors.username
                          ? data?.contact?.contactForm?.validationMessages
                              ?.nameValidationMessage
                          : data?.contact?.contactForm?.validationMessages
                              ?.specialCharactersAndNumbersValidationMessage}
                      </p>
                    )}
                  </div>
                </div>
                <div>
                  <label className="cursor-pointer">
                    {data?.contact?.contactForm?.email}{' '}
                    <span className="text-primary">*</span>
                    <input
                      ref={mailRef}
                      type="text"
                      name="email"
                      className="w-full text-white p-1 appearance-none bg-transparent rounded-none border-b border-white focus:outline-none focus:border-primary"
                      value={formData.email}
                      onChange={(e) => {
                        handleChange(e);
                        if (isSubmitClicked) handleBlur(e);
                      }}
                      onBlur={handleBlur}
                      tabIndex={2}
                    ></input>
                  </label>

                  <div className="lg:w-[25rem] h-1 py-3">
                    {errors.email && (
                      <p className="text-primary text-xs  uppercase">
                        {' '}
                        {
                          data?.contact?.contactForm?.validationMessages
                            ?.emailValidationMessage
                        }
                      </p>
                    )}
                  </div>
                </div>
                <div>
                  <label className="cursor-pointer">
                    {data?.contact?.contactForm?.contactNumber}

                    <input
                      // type="number"
                      ref={numberRef}
                      name="number"
                      className="w-full text-white p-1 appearance-none bg-transparent rounded-none border-b border-white focus:outline-none focus:border-primary"
                      value={formData.number}
                      onChange={(e) => {
                        handleChange(e);
                        if (isSubmitClicked) handleBlur(e);
                      }}
                      onBlur={handleBlur}
                      onInput={handleLength}
                      maxLength={24}
                      tabIndex={3}
                    ></input>
                  </label>

                  <div className="lg:w-[25rem] h-1 py-3">
                    {errors.number && (
                      <p className="text-primary text-xs  uppercase">
                        {' '}
                        {
                          data?.contact?.contactForm?.validationMessages
                            ?.contactNumberValidationMessage
                        }
                      </p>
                    )}
                  </div>
                </div>
                <div className="relative" ref={locationMainRef}>
                  <label
                    className="cursor-pointer"
                    ref={locationRef}
                    onClick={() => {
                      setShowLocationDropdon(!showLocationDropdown);
                      setTimeout(() => {
                        locationInputRef?.current?.focus();
                      }, 200);
                    }}
                    tabIndex={4}
                  >
                    {data?.contact?.contactForm?.locations}{' '}
                    <span className="text-primary">*</span>
                    <div className="relative cursor-pointer">
                      <span
                        className="absolute text-[#fefaf67d] text-[18px] top-[3px]"
                        onClick={() => {
                          setShowLocationDropdon(true);
                        }}
                      >
                        {formData?.location}
                      </span>
                      <div
                        type="text"
                        className="block lg:flex justify-end w-full text-white p-1 appearance-none rounded-none bg-transparent border-b border-white focus:outline-none focus:border-primary h-[2rem]"
                      >
                        <svg
                          className="float-right mb-3"
                          width="15"
                          height="10"
                          viewBox="0 0 10 6"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M9.50781 1.25391L5.25391 5.50781L1 1.25391"
                            stroke="#FEFBF7"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          ></path>
                        </svg>
                      </div>
                    </div>
                  </label>
                  {showLocationDropdown && (
                    <div className="absolute bg-black border border-t-0 cursor-pointer w-full z-10 ">
                      <input
                        type="search"
                        placeholder="Search"
                        id="location"
                        maxLength="30"
                        className="appearance-none w-[100%] py-[.4rem] px-[1rem] bg-red text-white-700 sticky cursor-unset"
                        autoComplete="off"
                        onChange={handleLocationSearch}
                        ref={locationInputRef}
                        onBlur={(e) => {
                          setTimeout(() => {
                            e.target.value = '';
                            setCountryList(sortedCountries);
                          }, 400);
                        }}
                      />
                      <div
                        id="dropdownList"
                        className="dropdown-list z-10 bg-transparent mt-1 h-[10rem] overflow-auto scrollable border-t border-[#ffffff6b]"
                      >
                        {countryList.map((country) => (
                          <p
                            key={country.name}
                            className={`dropdown-item py-[.4rem] px-[1rem] text-xs ${formData?.location === country?.name ? 'text-primary' : ''}`}
                            onClick={handleCountryClick}
                          >
                            {country.name}
                          </p>
                        ))}
                      </div>
                    </div>
                  )}
                  <div className="lg:w-[25rem] h-1 py-3">
                    {errors.location && (
                      <p className="text-primary text-xs  uppercase">
                        {' '}
                        {
                          data?.contact?.contactForm?.validationMessages
                            ?.locationValidationMessage
                        }
                      </p>
                    )}
                  </div>
                </div>
                <div>
                  <label className="cursor-pointer">
                    {data?.contact?.contactForm?.areaOfInterest}

                    <input
                      type="text"
                      name="areaOfInterest"
                      className="w-full text-white p-1 appearance-none bg-transparent rounded-none border-b border-white focus:outline-none focus:border-primary"
                      value={formData.areaOfInterest}
                      onChange={handleChange}
                      tabIndex={5}
                    ></input>
                  </label>
                  <div className="lg:w-[25rem] h-1 py-3"></div>
                </div>
                <div className="relative" ref={hearAboutUsMainRef}>
                  <label
                    className="cursor-pointer"
                    onClick={() => {
                      setShowHearAboutUsDropdown(!showHearAboutUsDropdown);
                      setTimeout(() => {
                        hearAboutUsInputRef?.current?.focus();
                      }, 200);
                    }}
                    ref={hearAboutUsRef}
                    tabIndex={6}
                  >
                    {
                      data?.contact?.contactForm?.howDidYouHearAboutUsSection
                        ?.howDidYouHearAboutUsText
                    }{' '}
                    <span className="text-primary">*</span>
                    <div id="hear-dropdown" className="relative cursor-pointer">
                      <span className="absolute text-[#fefaf67d] text-[18px] top-[3px]">
                        {formData?.hearAboutUs}
                      </span>
                      <div
                        type="text"
                        className="block lg:flex justify-end w-full text-white p-1 rounded-none appearance-none bg-transparent border-b border-white focus:outline-none focus:border-primary h-[2rem]"
                      >
                        <svg
                          className="float-right mb-3"
                          width="15"
                          height="10"
                          viewBox="0 0 10 6"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M9.50781 1.25391L5.25391 5.50781L1 1.25391"
                            stroke="#FEFBF7"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          ></path>
                        </svg>
                      </div>
                    </div>
                  </label>
                  {showHearAboutUsDropdown && (
                    <div className="absolute bg-black border border-t-0 cursor-pointer  w-full z-10 ">
                      <input
                        type="search"
                        placeholder="Search"
                        id="hearAboutUs"
                        maxLength="30"
                        className="appearance-none w-[100%] py-[.4rem] px-[1rem] bg-red text-white-700 sticky cursor-unset"
                        autoComplete="off"
                        onChange={handleHearAboutSearch}
                        ref={hearAboutUsInputRef}
                        onBlur={(e) => {
                          setTimeout(() => {
                            e.target.value = '';
                            setHearAboutList(
                              data?.contact?.contactForm?.howDidYouHearAboutUsSection?.howDidYouHearAboutUsValues.sort(),
                            );
                          }, 400);
                        }}
                      />
                      <div
                        id="dropdownList"
                        className="dropdown-list bg-transparent mt-1 h-[10rem] overflow-auto scrollable border-t border-[#ffffff6b]"
                      >
                        {hearAboutList?.map((value) => (
                          <p
                            key={value}
                            className={`dropdown-item py-[.4rem] px-[1rem] text-xl ${
                              formData?.hearAboutUs === value
                                ? 'text-primary'
                                : ''
                            }`}
                            onClick={handleHearAboutUsClick}
                          >
                            {value}
                          </p>
                        ))}
                      </div>
                    </div>
                  )}
                  <div className="lg:w-[25rem] h-1 py-3">
                    {errors.hearAboutUs && (
                      <p className="text-primary text-xs  uppercase">
                        {
                          data?.contact?.contactForm?.validationMessages
                            ?.howDidYouHearAboutUsValidationMessage
                        }
                      </p>
                    )}
                  </div>
                </div>
              </div>
              <div className="vs:pt-8 md:pt-[4rem]">
                <label className="cursor-pointer">
                  {data?.contact?.contactForm?.addAMessage}{' '}
                  <span className="text-primary">*</span>
                  <textarea
                    ref={messageRef}
                    name="addAMessage"
                    className="w-full text-white p-1 appearance-none bg-transparent rounded-none border-b border-white focus:outline-none focus:border-primary h-[140px]"
                    value={formData.addAMessage}
                    onChange={(e) => {
                      handleChange(e);
                      if (isSubmitClicked) handleBlur(e);
                    }}
                    onBlur={handleBlur}
                    maxLength={500}
                    tabIndex={7}
                  ></textarea>
                </label>
                <div className="py-3 h-1">
                  {errors.addAMessage && (
                    <p className="text-primary text-xs  uppercase">
                      {' '}
                      {
                        data?.contact?.contactForm?.validationMessages
                          ?.addAMessageValidationMessage
                      }
                    </p>
                  )}
                </div>
              </div>
              <div className="pt-[3rem] contact-checkbox" ref={termsRef}>
                <label
                  className="block lg:flex justify-start items-center md:text-[14px] break-all	
                
                "
                  tabIndex={8}
                >
                  {showCheckTick && (
                    <span
                      className={`text-primary p-[2px] absolute leading-[24px] lg:leading-[16px] cursor-pointer ${userAgent.includes('Firefox') || userAgent.includes('Fxios') ? 'left-[44px]' : ''} lg:left-auto`}
                    >
                      ✓
                    </span>
                  )}
                  <input
                    type="checkbox"
                    name="terms"
                    onChange={handleTermsChange}
                    className={`w-4 h-4 mr-2 appearance-none text-primary border border-primary border-solid rounded cursor-pointer float-left mt-[5px] lg:mt-0`}
                  />
                  <span className="table break-keep">
                    {data?.contact?.contactForm?.byTickingThisBoxCheckbox}
                    <Link
                      className="ml-1 text-primary"
                      target="_blank"
                      to={
                        data?.contact?.contactForm?.privacyPolicyLink
                          ? data?.contact?.contactForm?.privacyPolicyLink
                          : 'https://www.ptw.com/pdf/ptw_privacy_policy.pdf'
                      }
                    >
                      {data?.contact?.contactForm?.privacyPolicy}{' '}
                      <span className="text-primary">*</span>
                    </Link>
                  </span>
                </label>
                <div className="lg:w-[25rem] h-1 py-3">
                  {errors.terms && (
                    <p className="text-primary text-xs  uppercase">
                      {' '}
                      {
                        data?.contact?.contactForm?.validationMessages
                          ?.privacyPolicyValidationMessage
                      }
                    </p>
                  )}
                </div>
              </div>
              <div className="pt-2 mt-4 inline-flex items-center ">
                <button
                  className={`submit-btn border pr-[4rem] pl-[2rem] overflow-hidden relative rounded-[50px] uppercase h-11 ${isSending ? 'text-[#94db16]' : ''}`}
                  name=""
                >
                  {isSending
                    ? data?.contact?.contactForm?.messageSent
                    : data?.contact?.contactForm?.submitButton}
                  {/* <span className="animateText">SUBMIT</span> */}
                </button>
                <span
                  className="submit-span hover:bg-red-700 flex bg-black justify-center items-center border relative overflow-hidden ml-[-2.5rem] w-11 h-11 rounded-full cursor-pointer"
                  disabled=""
                  onClick={handleSubmit}
                >
                  <svg
                    width="16"
                    height="11"
                    viewBox="0 0 16 11"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M0.171875 5.27543L0.171875 5.77543L0.671875 5.77543L13.4612 5.77543L9.89449 9.34217L9.54094 9.69573L10.248 10.4028L10.6016 10.0493L15.0219 5.62895L15.3755 5.2754L15.0219 4.92184L10.6016 0.501512L10.248 0.147959L9.54094 0.855066L9.89449 1.20862L13.4613 4.77543L1.17188 4.77543L1.17188 2.1127L1.17188 1.6127L0.171875 1.6127L0.171875 2.1127L0.171875 5.27543Z"
                      fill="white"
                    ></path>
                  </svg>
                </span>
              </div>
              {/* <button className=" mt-4 p-4 border border-solid border-white ">
              {isSending ? 'Message Sent' : 'Submit'}
            </button> */}

              <div className="mb-5 mt-8">
                <p className="font-medium text-[16px]">
                  {data?.contact?.contactForm?.['1518studiosInc']}
                  <br></br>
                  {data?.contact?.contactForm?.['2024AllRightsReserved']}
                </p>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default ContactForm;
