import { Link } from 'react-router-dom';
import { getLanguage } from '../../../constants/GeneralConstants';
import '../../../css/pagescss/ProjectsPage.css';
import { useState } from 'react';
import AnimateOnScroll from '../../../components/AnimationScroll';

const ProjectCard = ({
  project,
  data,
  lowQualityImg,
  highQualityImg,
  index,
}) => {
  const [src, setSrc] = useState(lowQualityImg);
  const [isLoaded, setIsLoaded] = useState(false);

  const handleOnLoad = (hqImg) => {
    setSrc(hqImg);
    setIsLoaded(true);
  };
  const handlezooming = (e) => {
    document.querySelector(`.project-image-${index}`).style.transform =
      'scale(1.05)';

    e.preventDefault();
  };
  const handlezoomoff = (e) => {
    document.querySelector(`.project-image-${index}`).style.transform =
      'scale(1)';

    e.preventDefault();
  };
  return (
    <>
      <div className=" flex gap-6 mb-8 flex-col uppercase  group image-container ">
        <div className="relative">
          <AnimateOnScroll animation="zoom-in">
            <img
              className={`project-image-${index}`}
              src={src}
              onLoad={() => handleOnLoad(highQualityImg)}
              alt="project listing image "
              style={{ filter: isLoaded ? 'none' : 'blur(7px)' }}
            ></img>
          </AnimateOnScroll>
          <Link
            to={
              getLanguage() === 'en'
                ? `/projects/${project?.projectURL}`
                : `/${getLanguage()}/projects/${project?.projectURL}`
            }
          >
            <button
              className="w-[7rem] h-[7rem] bg-primary rounded-[100%] absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 hidden group-hover:block transition duration-300 ease-in-out hover-button"
              onMouseLeave={(e) => handlezoomoff(e)}
              onMouseOver={(e) => handlezooming(e)}
            >
              {data?.projects?.view}
              <br />
              {data?.projects?.project}
            </button>
          </Link>
        </div>
        <div className="flex justify-between items-baseline bg-[url('/public/images/Rectangle_bottom_right.png')] bg-right-bottom bg-no-repeat">
          <div>
            <h2 className="text-[26px] font-extrabold line-clamp-1">
              {project?.title}
            </h2>
            <p className="text-base leading-[24px] text-[#aeaeae]">
              {project?.clientName}
            </p>
          </div>
          <div>
            <p className="text-base leading-[24px] text-[#aeaeae]">
              {' '}
              {project?.releaseYears}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProjectCard;
