import React, { useEffect, useState } from 'react';
import Footer from './Footer';
import { getLanguage } from '../constants/GeneralConstants';
import { getPageNotFoundData } from '../apiservices/ApiService';
import { useParams } from 'react-router-dom';

const PageNotFound = () => {
  const [data, setData] = useState();
  const { lang } = useParams();
  const fetchData = async () => {
    const response = await getPageNotFoundData(getLanguage());
    setData(response);
  };
  useEffect(() => {
    fetchData();
  }, [lang]);
  return (
    <>
      {data ? (
        <div className="p-10 flex flex-col justify-center items-center h-[100vh]">
          <h1 className="text-[36px] mb-2">{data?.pageNotFound}</h1>
          <p className="text-[18px]">{data?.pageYouAreLookingDoesNotExist}</p>
          <a href={getLanguage() === 'en' ? '/' : `/${getLanguage()}`}>
            <button className="bg-primary border-none rounded-[16px] py-[6px] px-[12px] mt-4">
              {data?.backToHome}
            </button>
          </a>
        </div>
      ) : (
        ''
      )}
      <Footer />
    </>
  );
};

export default PageNotFound;
