import parse from 'html-react-parser';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getLanguage, navigateTo } from '../../constants/GeneralConstants';
import { callNDAContent, submitNdaDetails } from '../../apiservices/ApiService';
import { useNavigate } from 'react-router-dom';
import { clientUrls } from '../../constants/UrlConstants';
import Cookies from 'js-cookie';
import Loader from '../../components/Loader';

const NdaModal = ({ displayModal, callApi }) => {
  const navigate = useNavigate();
  const lang = useSelector((state) => state.language.selectedLanguage);
  const [ndaContent, setNdaContent] = useState(null);
  const [displayNdaModal, setDisplayNdaModal] = useState(displayModal);
  const [modalButtonDisabled, setModalButtonDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [submitTriggered, setSubmitTriggered] = useState(false);
  const [userData, setUserData] = useState({
    name: '',
    email: '',
  });
  const [err, setErr] = useState({
    nameErr: '',
    emailErr: '',
  });

  useEffect(() => {
    callNDAContentAPI(getLanguage());
  }, [lang]);

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  useEffect(() => {
    if (submitTriggered) {
      if (modalButtonDisabled === false) {
        clickToSubmitData();
      }
      setSubmitTriggered(false);
    }
  }, [submitTriggered]);

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      setSubmitTriggered(true);
    }
  };

  const callNDAContentAPI = async (language) => {
    setLoading(true);
    try {
      let data = await callNDAContent(language);
      setNdaContent(data?.data?.nda);
      setDisplayNdaModal(true);
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  const clickToSubmitData = () => {
    // setLoader(true);
    let validate = checkValidations();
    if (validate) {
      setLoading(true);
      setModalButtonDisabled(true);
      let obj = {
        name: userData.name,
        email: userData.email,
      };

      submitNdaDetails(obj).then((res) => {
        if (res.data.status_code === 200) {
          localStorage.setItem('is_signed_ptw_nda', 1);
          Cookies.set('is_signed_ptw_nda', 1);
          setModalButtonDisabled(false);
          callApi();
          setLoading(false);
        } else {
          console.log('some error');
        }
      });
    }
  };

  const handleInputText = (e) => {
    const { value, id } = e.target;
    setUserData((data) => ({ ...data, [id]: value }));
  };

  const checkValidations = () => {
    let validation = true;
    let name = userData.name.trim();
    const regex = /^[\p{L}\p{M}\s'-]+$/u;

    if (!regex.test(name)) {
      validation = false;
      setErr((data) => ({
        ...data,
        nameErr: ndaContent?.pleaseEnterValidName,
      }));
    } else if (name === '') {
      validation = false;
      setErr((data) => ({ ...data, nameErr: ndaContent?.pleaseEnterName }));
    } else if (name.replace(/\s+/g, '').length < 3) {
      validation = false;
      setErr((data) => ({
        ...data,
        nameErr: ndaContent?.nameMustBeAtLeast3CharactersLong,
      }));
    } else {
      setErr((data) => ({ ...data, nameErr: '' }));
    }

    if (userData.email.length <= 0) {
      validation = false;
      setErr((data) => ({ ...data, emailErr: ndaContent?.pleaseEnterEmail }));
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(userData.email)
    ) {
      validation = false;
      setErr((data) => ({
        ...data,
        emailErr: ndaContent?.pleaseEnterValidEmail,
      }));
    } else {
      setErr((data) => ({ ...data, emailErr: '' }));
    }

    return validation;
  };
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          {ndaContent && (
            <div
              id="openModal-about"
              style={
                displayNdaModal
                  ? { opacity: 1, pointerEvents: 'auto', background: '#0C0C0C' }
                  : { opacity: 0, pointerEvents: 'none' }
              }
              className="modalDialog flex justify-center items-start lg:items-center"
            >
              <div className="sliderShadow absolute hidden1 block top-0 left-0">
                <img
                  src="/images/portfolio-shadow.png"
                  alt="ptw art"
                  className="h-[100vh]"
                />
              </div>
              <div className="flex justify-center items-center h-full">
                <div className="h-[80%] overflow-y-scroll overflow-x-hidden lg:h-full custom-scroll ">
                  <div className="w-[85%] lg:w-[75%] mx-auto my-10 lg:my-20 relative px-6 pb-8 pt-8 lg:px-24 lg:pb-16 lg:pt-8 border border-[#ffffff4d] shadow-lg">
                    <button
                      className="close"
                      onClick={(e) => {
                        setDisplayNdaModal(false);
                        navigate(navigateTo(clientUrls.home));
                      }}
                    >
                      X
                    </button>
                    <h2 className="text-center font-semibold">
                      {ndaContent?.agreeToAnNDA}
                    </h2>
                    <div className="nda_content mt-4 p-3 overflow-auto h-32 lg:h-44 text-[16px] custom-scroll">
                      <p className="">{parse(ndaContent?.art_nda_content)}</p>
                    </div>
                    <div className="user_details mt-4">
                      <div className="grid grid-cols-1 md:grid-cols-2">
                        <div className="p-3">
                          <div className="text-left">
                            <label
                              className="portfolio-modal-label uppercase font-medium"
                              htmlFor="name"
                            >
                              {ndaContent?.name} :{' '}
                              <span className="text-primary">*</span>
                            </label>
                            <input
                              type="text"
                              id="name"
                              className="portfolio-modal-input w-full text-white p-1 appearance-none bg-transparent rounded-none border-b border-white focus:outline-none focus:border-primary"
                              maxLength={25}
                              onChange={(e) => handleInputText(e)}
                            />
                            <div className="validation-message text-left text-primary">
                              {err.nameErr}
                            </div>
                          </div>
                        </div>
                        <div className="p-3">
                          <div className="text-left">
                            <label
                              className="portfolio-modal-label uppercase font-medium"
                              htmlFor="email"
                            >
                              {ndaContent?.email} :{' '}
                              <span className="text-primary">*</span>
                            </label>
                            <input
                              type="email"
                              id="email"
                              className="portfolio-modal-input w-full text-white p-1 appearance-none bg-transparent rounded-none border-b border-white focus:outline-none focus:border-primary"
                              maxLength={150}
                              onChange={(e) => handleInputText(e)}
                            />
                            <div className="validation-message text-left text-primary">
                              {err.emailErr}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className=" flex justify-center items-center px-4 gap-2 xs:gap-3">
                      <div
                        className="pt-2 mt-4 inline-flex items-center hover-bg"
                        onClick={
                          modalButtonDisabled
                            ? null
                            : (e) => clickToSubmitData(e)
                        }
                      >
                        <button
                          className={
                            modalButtonDisabled
                              ? 'portfolio-btn-disabled border pt-[0.5rem] pr-[5rem] pb-[0.7rem] pl-[3rem] overflow-hidden relative rounded-[50px] uppercase w-52'
                              : 'border pr-[3rem] pl-[1rem] overflow-hidden relative rounded-[50px] uppercase h-11 w-32 xs:w-36 sm:w-52  text-[10px] xs:text-xs sm:text-base '
                          }
                          disabled={modalButtonDisabled}
                        >
                          {loading
                            ? ndaContent?.loading
                            : ndaContent?.art_i_agree}
                        </button>
                        <span className="arrowcircle hover:bg-red-700 flex bg-black justify-center items-center border relative overflow-hidden ml-[-2.2rem] w-11 h-11 rounded-full cursor-pointer">
                          <svg
                            width="16"
                            height="11"
                            viewBox="0 0 16 11"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M0.171875 5.27543L0.171875 5.77543L0.671875 5.77543L13.4612 5.77543L9.89449 9.34217L9.54094 9.69573L10.248 10.4028L10.6016 10.0493L15.0219 5.62895L15.3755 5.2754L15.0219 4.92184L10.6016 0.501512L10.248 0.147959L9.54094 0.855066L9.89449 1.20862L13.4613 4.77543L1.17188 4.77543L1.17188 2.1127L1.17188 1.6127L0.171875 1.6127L0.171875 2.1127L0.171875 5.27543Z"
                              fill="white"
                            ></path>
                          </svg>
                        </span>
                      </div>

                      <div
                        className="pt-2 mt-4 inline-flex items-center hover-bg"
                        onClick={() => {
                          if (getLanguage() === 'en') {
                            navigate('/');
                          } else {
                            navigate(`/${getLanguage()}`);
                          }
                        }}
                      >
                        <button
                          className={
                            modalButtonDisabled
                              ? 'portfolio-btn-disabled border pt-[0.5rem] pr-[5rem] pb-[0.7rem] pl-[3rem] overflow-hidden relative rounded-[50px] uppercase w-52'
                              : 'border pr-[3rem] pl-[2rem] overflow-hidden relative rounded-[50px] uppercase h-11 w-32 xs:w-36 sm:w-52  text-[10px] xs:text-xs sm:text-base flex-1'
                          }
                          disabled={modalButtonDisabled}
                        >
                          {loading
                            ? ndaContent?.loading
                            : ndaContent?.iDoNotAgree}
                        </button>
                        <span className="arrowcircle hover:bg-red-700 flex bg-black justify-center items-center border relative overflow-hidden ml-[-2.2rem] w-11 h-11 rounded-full cursor-pointer">
                          <svg
                            width="16"
                            height="11"
                            viewBox="0 0 16 11"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M0.171875 5.27543L0.171875 5.77543L0.671875 5.77543L13.4612 5.77543L9.89449 9.34217L9.54094 9.69573L10.248 10.4028L10.6016 10.0493L15.0219 5.62895L15.3755 5.2754L15.0219 4.92184L10.6016 0.501512L10.248 0.147959L9.54094 0.855066L9.89449 1.20862L13.4613 4.77543L1.17188 4.77543L1.17188 2.1127L1.17188 1.6127L0.171875 1.6127L0.171875 2.1127L0.171875 5.27543Z"
                              fill="white"
                            ></path>
                          </svg>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default NdaModal;
