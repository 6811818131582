// Component For Footer

import { Link } from 'react-router-dom';
import '../css/componentscss/Footer.css';
import Text from './Text';
import { clientUrls } from '../constants/UrlConstants';
import { useSelector } from 'react-redux';
import {
  currentYear,
  navigateTo,
  stringToUpperCase,
} from '../constants/GeneralConstants';
import packageJson from '../../package.json';

const Footer = () => {
  const footerData = useSelector((state) => state.language.footerData);
  // console.log(footerData?.)
  const pageScrollTop = () => {
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    }, 200);
  };
  return (
    <>
      <footer>
        <div className="footer">
          <div className="footerlinks">
            <div className="leftpart">
              <Text
                title={stringToUpperCase(
                  footerData?.connectText ? footerData?.connectText : 'CONNECT',
                )}
                titleTag="label"
                className={''}
              />
              <div className="links flex lg:gap-4">
                <h6 className="mt-[0.1rem] lg:-mr-4">
                  <a
                    target="_blank"
                    rel="noreferrer noopener"
                    href={footerData?.socialMediaLogosWithLinks?.artStationLink}
                  >
                    <svg
                      xmlns={
                        footerData?.socialMediaLogosWithLinks?.artStationLogo
                      }
                      x="0px"
                      y="0px"
                      width={50}
                      height={50}
                      viewBox="0 0 50 50"
                      // className="logo fill-black"
                      className="fill-white lg:hover:fill-primary active:fill-primary logo"
                      // style={{ fill: 'rgb(255, 255, 255)' }}
                    >
                      <path
                        d="M19.2591 0.337891C20.2948 0.337891 21.3306 0.337891 22.3664 0.337891C24.0495 0.337891 25.3442 1.13074 26.1858 2.61735C28.9695 7.7709 31.7531 12.9575 34.5367 18.1441C35.4754 19.928 36.4141 21.6789 37.3851 23.4628C38.2591 25.0485 38.2267 26.6342 37.3851 28.1869C36.3817 30.0038 35.4107 31.8538 34.4073 33.6708C34.0836 34.2654 33.6628 34.6288 32.9507 34.6288C32.271 34.6288 31.8502 34.2654 31.5589 33.6708C25.9269 23.3637 20.2625 13.0566 14.6304 2.74949C14.1449 1.89056 14.3068 1.03164 14.9865 0.56914C15.2131 0.403962 15.5044 0.337891 15.8604 0.337891C16.9933 0.337891 18.1262 0.337891 19.2914 0.337891H19.2591ZM32.9183 30.0369C33.5333 28.9137 34.0512 27.8896 34.6339 26.8985C35.087 26.1387 35.0546 25.478 34.6339 24.7182C30.9439 17.9128 27.3187 11.1075 23.6611 4.26913C23.3374 3.64145 22.8519 3.34413 22.1398 3.34413C21.2335 3.34413 20.3596 3.34413 19.4533 3.34413C18.3528 3.34413 18.3528 3.34413 18.903 4.3352C22.9814 11.8012 27.0597 19.3003 31.1381 26.7664C31.7207 27.8235 32.3034 28.8806 32.9183 30.0038V30.0369Z"
                        // fill="white"
                      />
                      <path
                        d="M12.1704 24.1569C15.7308 24.1569 19.2913 24.1569 22.8518 24.1569C23.5639 24.1569 24.1141 24.5533 24.3407 25.2471C24.5349 25.8747 24.3083 26.5355 23.7581 26.9319C23.4344 27.1631 23.1107 27.1962 22.7223 27.1962C19.3237 27.1962 15.925 27.1962 12.5264 27.1962C9.9046 27.1962 7.31516 27.1962 4.69335 27.1962C4.1431 27.1962 4.1431 27.1962 4.40204 27.6917C4.9523 28.6497 5.50255 29.6078 6.02044 30.5988C6.40885 31.3256 6.92674 31.5899 7.70357 31.5899C14.1124 31.5899 20.4889 31.5899 26.8978 31.5899C27.9336 31.5899 28.5809 32.2506 28.5162 33.2086C28.4838 33.9354 27.9012 34.5631 27.1891 34.6292C27.0273 34.6292 26.8654 34.6292 26.7036 34.6292C20.3595 34.6292 14.0153 34.6292 7.63884 34.6292C5.79386 34.6292 4.40204 33.8363 3.49574 32.1845C2.45996 30.3015 1.39182 28.4515 0.291307 26.6015C-0.38842 25.4783 0.291307 24.0908 1.7155 24.0908C5.21124 24.1569 8.70698 24.0908 12.2027 24.0908L12.1704 24.1569Z"
                        // fill="white"
                      />
                      <path
                        d="M12.3969 22.2738C9.83982 22.2738 7.28275 22.2738 4.75805 22.2738C3.23676 22.2738 2.55703 21.1175 3.30149 19.7631C5.89093 15.1711 8.51274 10.5792 11.1345 6.02025C11.8466 4.79794 13.2061 4.79794 13.8858 6.02025C16.4753 10.6452 19.0323 15.2702 21.6218 19.8952C22.2691 21.0514 21.557 22.2738 20.2623 22.2738C17.6405 22.2738 15.0511 22.2738 12.4293 22.2738H12.3969ZM12.3645 19.1684C13.9506 19.1684 15.569 19.1684 17.155 19.1684C17.511 19.1684 17.6081 19.1023 17.4139 18.739C15.8279 15.8979 14.2419 13.0238 12.6235 10.1827C12.3969 9.78631 12.3645 9.78631 12.1379 10.1827C10.5519 13.0238 8.96589 15.8649 7.34749 18.7059C7.08855 19.1684 7.08855 19.2015 7.6388 19.2015C9.19246 19.2015 10.7785 19.2015 12.3322 19.2015L12.3645 19.1684Z"
                        // fill="white"
                      />
                    </svg>
                  </a>
                </h6>
                <h6 className="mr-4 lg:mr-0">
                  <a
                    target="_blank"
                    rel="noreferrer noopener"
                    href={footerData?.socialMediaLogosWithLinks?.xLink}
                  >
                    <svg
                      xmlns={footerData?.socialMediaLogosWithLinks?.xLogo}
                      x="0px"
                      y="0px"
                      width={40}
                      height={40}
                      viewBox="0 0 50 50"
                      className="fill-white lg:hover:fill-primary active:fill-primary"
                      // style={{ fill: 'rgb(255, 255, 255)' }}
                    >
                      <path d="M 5.9199219 6 L 20.582031 27.375 L 6.2304688 44 L 9.4101562 44 L 21.986328 29.421875 L 31.986328 44 L 44 44 L 28.681641 21.669922 L 42.199219 6 L 39.029297 6 L 27.275391 19.617188 L 17.933594 6 L 5.9199219 6 z M 9.7167969 8 L 16.880859 8 L 40.203125 42 L 33.039062 42 L 9.7167969 8 z"></path>
                    </svg>
                  </a>
                </h6>
                <h6 className="mt-[0.1rem] mr-4 lg:mr-0">
                  <a
                    target="_blank"
                    rel="noreferrer noopener"
                    href={footerData?.socialMediaLogosWithLinks?.instagramLink}
                  >
                    <svg
                      xmlns={
                        footerData?.socialMediaLogosWithLinks?.instagramLogo
                      }
                      x="0px"
                      y="0px"
                      width={37}
                      height={37}
                      viewBox="0 0 26 26"
                      className="fill-white lg:hover:fill-primary active:fill-primary"
                      // style={{ fill: 'rgb(255, 255, 255)' }}
                    >
                      <path d="M 7.546875 0 C 3.390625 0 0 3.390625 0 7.546875 L 0 18.453125 C 0 22.609375 3.390625 26 7.546875 26 L 18.453125 26 C 22.609375 26 26 22.609375 26 18.453125 L 26 7.546875 C 26 3.390625 22.609375 0 18.453125 0 Z M 7.546875 2 L 18.453125 2 C 21.527344 2 24 4.46875 24 7.546875 L 24 18.453125 C 24 21.527344 21.53125 24 18.453125 24 L 7.546875 24 C 4.472656 24 2 21.53125 2 18.453125 L 2 7.546875 C 2 4.472656 4.46875 2 7.546875 2 Z M 20.5 4 C 19.671875 4 19 4.671875 19 5.5 C 19 6.328125 19.671875 7 20.5 7 C 21.328125 7 22 6.328125 22 5.5 C 22 4.671875 21.328125 4 20.5 4 Z M 13 6 C 9.144531 6 6 9.144531 6 13 C 6 16.855469 9.144531 20 13 20 C 16.855469 20 20 16.855469 20 13 C 20 9.144531 16.855469 6 13 6 Z M 13 8 C 15.773438 8 18 10.226563 18 13 C 18 15.773438 15.773438 18 13 18 C 10.226563 18 8 15.773438 8 13 C 8 10.226563 10.226563 8 13 8 Z"></path>
                    </svg>
                  </a>
                </h6>
                <h6 className="mt-[0.15rem] mr-4 lg:mr-0 ml-[6px]">
                  <a
                    target="_blank"
                    rel="noreferrer noopener"
                    href={footerData?.socialMediaLogosWithLinks?.linkedinLink}
                  >
                    <svg
                      xmlns={
                        footerData?.socialMediaLogosWithLinks?.linkedinLogo
                      }
                      x="0px"
                      y="0px"
                      width={35}
                      height={35}
                      viewBox="0 0 26 26"
                      className="fill-white lg:hover:fill-primary active:fill-primary"
                      // style={{ fill: 'rgb(255, 255, 255)' }}
                    >
                      <path d="M 21.125 0 L 4.875 0 C 2.183594 0 0 2.183594 0 4.875 L 0 21.125 C 0 23.816406 2.183594 26 4.875 26 L 21.125 26 C 23.816406 26 26 23.816406 26 21.125 L 26 4.875 C 26 2.183594 23.816406 0 21.125 0 Z M 8.039063 22.070313 L 4 22.070313 L 3.976563 9.976563 L 8.015625 9.976563 Z M 5.917969 8.394531 L 5.894531 8.394531 C 4.574219 8.394531 3.722656 7.484375 3.722656 6.351563 C 3.722656 5.191406 4.601563 4.3125 5.945313 4.3125 C 7.289063 4.3125 8.113281 5.191406 8.140625 6.351563 C 8.140625 7.484375 7.285156 8.394531 5.917969 8.394531 Z M 22.042969 22.070313 L 17.96875 22.070313 L 17.96875 15.5 C 17.96875 13.910156 17.546875 12.828125 16.125 12.828125 C 15.039063 12.828125 14.453125 13.558594 14.171875 14.265625 C 14.066406 14.519531 14.039063 14.867188 14.039063 15.222656 L 14.039063 22.070313 L 9.945313 22.070313 L 9.921875 9.976563 L 14.015625 9.976563 L 14.039063 11.683594 C 14.5625 10.875 15.433594 9.730469 17.519531 9.730469 C 20.105469 9.730469 22.039063 11.417969 22.039063 15.046875 L 22.039063 22.070313 Z"></path>
                    </svg>
                  </a>
                </h6>
              </div>
              <div className="mobilelinks links" style={{ display: 'none' }}>
                <div className="topLine">
                  <h6>
                    <a
                      target="_blank"
                      rel="noreferrer noopener"
                      href="https://twitter.com/SideGlobal"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        x="0px"
                        y="0px"
                        width={40}
                        height={40}
                        viewBox="0 0 50 50"
                        className="fill-white lg:hover:fill-primary active:fill-primary"
                        // style={{ fill: 'rgb(255, 255, 255)' }}
                      >
                        <path d="M 5.9199219 6 L 20.582031 27.375 L 6.2304688 44 L 9.4101562 44 L 21.986328 29.421875 L 31.986328 44 L 44 44 L 28.681641 21.669922 L 42.199219 6 L 39.029297 6 L 27.275391 19.617188 L 17.933594 6 L 5.9199219 6 z M 9.7167969 8 L 16.880859 8 L 40.203125 42 L 33.039062 42 L 9.7167969 8 z"></path>
                      </svg>
                    </a>
                  </h6>
                  <h6>
                    <a
                      target="_blank"
                      rel="noreferrer noopener"
                      href="https://www.linkedin.com/company/side-audio/"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        x="0px"
                        y="0px"
                        width={40}
                        height={40}
                        viewBox="0 0 26 26"
                        className="fill-white lg:hover:fill-primary active:fill-primary"
                        // style={{ fill: 'rgb(255, 255, 255)' }}
                      >
                        <path d="M 21.125 0 L 4.875 0 C 2.183594 0 0 2.183594 0 4.875 L 0 21.125 C 0 23.816406 2.183594 26 4.875 26 L 21.125 26 C 23.816406 26 26 23.816406 26 21.125 L 26 4.875 C 26 2.183594 23.816406 0 21.125 0 Z M 8.039063 22.070313 L 4 22.070313 L 3.976563 9.976563 L 8.015625 9.976563 Z M 5.917969 8.394531 L 5.894531 8.394531 C 4.574219 8.394531 3.722656 7.484375 3.722656 6.351563 C 3.722656 5.191406 4.601563 4.3125 5.945313 4.3125 C 7.289063 4.3125 8.113281 5.191406 8.140625 6.351563 C 8.140625 7.484375 7.285156 8.394531 5.917969 8.394531 Z M 22.042969 22.070313 L 17.96875 22.070313 L 17.96875 15.5 C 17.96875 13.910156 17.546875 12.828125 16.125 12.828125 C 15.039063 12.828125 14.453125 13.558594 14.171875 14.265625 C 14.066406 14.519531 14.039063 14.867188 14.039063 15.222656 L 14.039063 22.070313 L 9.945313 22.070313 L 9.921875 9.976563 L 14.015625 9.976563 L 14.039063 11.683594 C 14.5625 10.875 15.433594 9.730469 17.519531 9.730469 C 20.105469 9.730469 22.039063 11.417969 22.039063 15.046875 L 22.039063 22.070313 Z"></path>
                      </svg>
                    </a>
                  </h6>
                </div>
                <div className="topLine">
                  <h6>
                    <a
                      target="_blank"
                      rel="noreferrer noopener"
                      href="https://www.instagram.com/sideglobal/"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        x="0px"
                        y="0px"
                        width={40}
                        height={40}
                        viewBox="0 0 48 48"
                        className="fill-white lg:hover:fill-primary active:fill-primary"
                        // style={{ fill: 'rgb(255, 255, 255)' }}
                      >
                        <path d="M 16.5 5 C 10.159 5 5 10.159 5 16.5 L 5 31.5 C 5 37.841 10.159 43 16.5 43 L 31.5 43 C 37.841 43 43 37.841 43 31.5 L 43 16.5 C 43 10.159 37.841 5 31.5 5 L 16.5 5 z M 34 12 C 35.105 12 36 12.895 36 14 C 36 15.104 35.105 16 34 16 C 32.895 16 32 15.104 32 14 C 32 12.895 32.895 12 34 12 z M 24 14 C 29.514 14 34 18.486 34 24 C 34 29.514 29.514 34 24 34 C 18.486 34 14 29.514 14 24 C 14 18.486 18.486 14 24 14 z M 24 17 A 7 7 0 1 0 24 31 A 7 7 0 1 0 24 17 z"></path>
                      </svg>
                    </a>
                  </h6>
                  <h6>
                    <a
                      target="_blank"
                      rel="noreferrer noopener"
                      href="https://www.youtube.com/@sideglobal2237"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        x="0px"
                        y="0px"
                        width={40}
                        height={40}
                        viewBox="0 0 50 50"
                        className="fill-white lg:hover:fill-primary active:fill-primary"
                        // style={{ fill: 'rgb(255, 255, 255)' }}
                      >
                        <path d="M 44.898438 14.5 C 44.5 12.300781 42.601563 10.699219 40.398438 10.199219 C 37.101563 9.5 31 9 24.398438 9 C 17.800781 9 11.601563 9.5 8.300781 10.199219 C 6.101563 10.699219 4.199219 12.199219 3.800781 14.5 C 3.398438 17 3 20.5 3 25 C 3 29.5 3.398438 33 3.898438 35.5 C 4.300781 37.699219 6.199219 39.300781 8.398438 39.800781 C 11.898438 40.5 17.898438 41 24.5 41 C 31.101563 41 37.101563 40.5 40.601563 39.800781 C 42.800781 39.300781 44.699219 37.800781 45.101563 35.5 C 45.5 33 46 29.398438 46.101563 25 C 45.898438 20.5 45.398438 17 44.898438 14.5 Z M 19 32 L 19 18 L 31.199219 25 Z"></path>
                      </svg>
                    </a>
                  </h6>
                </div>
              </div>
              <div className="mailto">
                <Text
                  title={stringToUpperCase(
                    footerData?.getInTouchText
                      ? footerData?.getInTouchText
                      : '',
                  )}
                  titleTag="label"
                  className={''}
                />
                {/* <h4 className="email-font text-[30px] lg:text-[40px]"> */}

                <h4 className="email-font text-[20px] lg:text-[32px]">
                  <a
                    className="underline font-bold  lg:hover:text-primary active:text-primary"
                    href={`mailto:${footerData?.getInTouchTextEmail ? footerData?.getInTouchTextEmail : 'connect@1518studios.com'}`}
                  >
                    {footerData?.getInTouchTextEmail
                      ? footerData?.getInTouchTextEmail
                      : 'connect@1518studios.com'}
                  </a>
                </h4>
              </div>
            </div>
            <div className="rightpart">
              <div className="footernav">
                <h6 onClick={pageScrollTop}>
                  <Link
                    to={navigateTo(clientUrls.services)}
                    className="cursor-pointer lg:hover:text-primary active:text-primary "
                  >
                    {stringToUpperCase(
                      footerData?.footerMenuLinks?.servicesText
                        ? footerData?.footerMenuLinks?.servicesText
                        : 'services',
                    )}
                  </Link>
                </h6>
                <h6 onClick={pageScrollTop}>
                  <Link
                    to={navigateTo(clientUrls.projects)}
                    className="cursor-pointer lg:hover:text-primary active:text-primary"
                  >
                    {stringToUpperCase(
                      footerData?.footerMenuLinks?.projectsText
                        ? footerData?.footerMenuLinks?.projectsText
                        : 'projects',
                    )}
                  </Link>
                </h6>
                <h6 onClick={pageScrollTop}>
                  <Link
                    to={navigateTo(clientUrls.contact)}
                    className="cursor-pointer lg:hover:text-primary active:text-primary"
                  >
                    {stringToUpperCase(
                      footerData?.footerMenuLinks?.contactText
                        ? footerData?.footerMenuLinks?.contactText
                        : 'contact',
                    )}
                  </Link>
                </h6>
                <h6 onClick={pageScrollTop}>
                  <Link
                    to={navigateTo(clientUrls.portfolio)}
                    className="cursor-pointer lg:hover:text-primary active:text-primary"
                  >
                    {stringToUpperCase(
                      footerData?.footerMenuLinks?.portfolioText
                        ? footerData?.footerMenuLinks?.portfolioText
                        : 'portfolio',
                    )}
                  </Link>
                </h6>
              </div>
            </div>
          </div>
          {/* <div>
                        <Image src="/images/Line-tell.png" alt={"Ptw art"} />
                    </div> */}
          <div className="footerbottom">
            <div className="leftpart">
              <Text
                className={'underlinne'}
                title={stringToUpperCase(
                  footerData?.bringingYourStoryToTheWorldText
                    ? footerData?.bringingYourStoryToTheWorldText
                    : 'BRINGING YOUR STORY TO THE WORLD.',
                )}
                titleTag="p"
              />
              <p>
                <a
                  href={
                    footerData?.partOfTheFamilyBrandsLink
                      ? footerData?.partOfTheFamilyBrandsLink
                      : 'https://www.ptw.com/'
                  }
                  target="_blank"
                  rel="noreferrer noopener"
                  className="underline lg:hover:text-primary active:text-primary"
                >
                  {stringToUpperCase(
                    footerData?.partOfTheFamilyBrandsText
                      ? footerData?.partOfTheFamilyBrandsText
                      : 'PART OF THE PTW FAMILY OF BRANDS',
                  )}
                </a>
              </p>
            </div>
            <div className="rightpart">
              <p>
                {/* <span>Version: {packageJson.version}</span>&nbsp; • &nbsp; */}
                <a
                  target="_blank"
                  rel="noreferrer noopener"
                  href={
                    footerData?.privacyPolicyLink
                      ? footerData?.privacyPolicyLink
                      : 'https://www.ptw.com/PDF/PTW_Privacy_Policy.pdf'
                  }
                  className="cursor-pointer lg:hover:text-primary active:text-primary"
                >
                  {stringToUpperCase(
                    footerData?.privacyPolicyText
                      ? footerData?.privacyPolicyText
                      : 'Privacy Policy',
                  )}
                </a>
                &nbsp; • &nbsp;
                <a
                  target="_blank"
                  rel="noreferrer noopener"
                  href={
                    footerData?.termsAndConditionsLink
                      ? footerData?.termsAndConditionsLink
                      : 'https://www.ptw.com/PDF/PTW_New_Website_Terms_of_Usage_2018.pdf'
                  }
                  className="cursor-pointer lg:hover:text-primary active:text-primary"
                >
                  {' '}
                  {stringToUpperCase(
                    footerData?.termsAndConditionsText
                      ? footerData?.termsAndConditionsText
                      : 'Terms & Conditions',
                  )}
                </a>
                &nbsp; © &nbsp;{currentYear}
              </p>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
