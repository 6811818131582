// Component For Button
import '../css/componentscss/Button.css';

const Button = ({
  children,
  onClick,
  className,
  type = 'button',
  disabled = false,
}) => {
  return (
    <button
      type={type}
      onClick={onClick}
      className={className}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

export default Button;
